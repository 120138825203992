<template>
  <div class="referral-container">
    <!-- Header with Refer and Refer History buttons -->
    <div class="header">
      <button
        :class="{ active: activeTab === 'refer' }"
        class="refer-btn"
        @click="activeTab = 'refer'"
      >
        Refer
      </button>
      <button
        :class="{ active: activeTab === 'referHistory' }"
        class="refer-history-btn"
        @click="activeTab = 'referHistory'"
      >
        Refer History
      </button>
    </div>

    <!-- Content Section -->
    <div class="content">
      <!-- Refer Section -->
      <div v-if="activeTab === 'refer'">
        <img :src="Promotions?.image" alt="Referral Banner" class="banner" />

        <div class="how-it-works-container">
          <p class="how-it-works-title">
            <font-awesome-icon
              :icon="['fas', 'circle-question']"
              style="color: #ffffff"
              class="bs-c-site-sidebar__list-icon icon sprite-sidebar"
            />
            {{$t("How It works") }}?
          </p>
        </div>
        <div class="steps-container">
           <div class="step-item">
            <p class="step-number yellow">1</p>
            <div class="step-box">
              <p class="step-title">{{ $t("Invite your Friends") }}</p>
              <span class="step-description">{{
                $t("Just Share your link.")
              }}</span>
            </div>
           </div>
           <div class="step-item">
            <p class="step-number orange">2</p>
            <div class="step-box">
              <p class="step-title">{{ $t("Sign up and first deposit") }}</p>
              <p class="step-description">
                {{ $t("Minimum first deposit") }}
                <span class="highlighted-text">{{
                  Promotions?.min_deposit
                }}</span
                >.
              </p>
            </div>
           </div>
           <div class="step-item">
            <p class="step-number blue">3</p>
            <div class="step-box">
              <p class="step-title">{{ $t("Complete your turnover") }}</p>
              <p class="step-description">
                {{ $t("Turnover amount will be") }}
                <span class="highlighted-text">
                  {{ Promotions?.reward_amount * Promotions?.wager_required }}
                  {{ Promotions?.currency }} </span
                >.
              </p>
            </div>
           </div>
           <div class="step-item">
            <p class="step-number green">
              <img src="../assets/medal.png" class="reward-icon" />
            </p>
            <div class="step-box">
              <p class="step-title">
                {{ $t("Get your rewards") }}
                <img src="../assets/medal.png" class="reward-small-icon" />
              </p>
              <p class="step-description">
                {{ $t("You") }} {{ $t("get") }}
                <span class="highlighted-text">
                  {{ Promotions?.reward_amount }}
                </span>
                {{ $t("cash bonus.") }}
              </p>
            </div>
          </div>
        </div>
        <div class="referral-link">
          <input
            class="referral-input"
            type="text"
            v-model="referralLink"
            readonly
          />
          <button @click="copyReferralLink">Copy the referral Link</button>
        </div>

        <div class="social-share">
          <button @click="shareOnFacebook" class="facebook">Facebook</button>
          <button @click="shareOnTwitter" class="twitter">Twitter</button>
          <button @click="shareOnWhatsApp" class="whatsapp">WhatsApp</button>
          <button @click="shareOnLinkedIn" class="linkedin">LinkedIn</button>
          <button @click="shareOnTelegram" class="telegram">Telegram</button>
        </div>

        <div class="how-it-works-container">
          <p class="how-it-works-title">
            <font-awesome-icon
              :icon="['fas', 'circle-question']"
              style="color: #ffffff"
              class="bs-c-site-sidebar__list-icon icon sprite-sidebar"
            />
            {{ $t("Description") }}?
          </p>
          <p style="text-align: left" v-html="Promotions?.description"></p>
        </div>
        <div class="how-it-works-container">
          <p class="how-it-works-title">
            <font-awesome-icon
              :icon="['fas', 'circle-question']"
              style="color: #ffffff"
              class="bs-c-site-sidebar__list-icon icon sprite-sidebar"
            />
            {{ $t("Eligibility") }}?
          </p>
          <p style="text-align: left" v-html="Promotions?.eligibility"></p>
        </div>
        <div class="how-it-works-container">
          <p class="how-it-works-title">
            <font-awesome-icon
              :icon="['fas', 'circle-question']"
              style="color: #ffffff"
              class="bs-c-site-sidebar__list-icon icon sprite-sidebar"
            />
            {{ $t("Rules") }}?
          </p>
          <p style="text-align: left" v-html="Promotions?.rules"></p>
        </div>
      </div>

      <!-- Refer History Section -->
      <div v-else-if="activeTab === 'referHistory'">
        <div class="referral-wallet">
          <p class="title">Referral Wallet</p>
          <p class="subtitle">Explore all your referrals</p>

          <div class="referral-box">
            <div class="referral-card">
              <div class="referral-image">
                <img src="../assets/earning.png" alt="" class="image" />
              </div>
              <div class="referral-info">
                <span class="info-title">You Referred</span>
                <span class="info-count">{{ referralHistory?.length }}</span>
                <span class="info-description">Lorem, ipsum dolor.</span>
              </div>
            </div>

            <div class="referral-card">
              <div class="referral-image">
                <img src="../assets/connections.png" alt="" class="image" />
              </div>
              <div class="referral-info">
                <span class="info-title">Your Referral</span>
                <span class="info-count">{{ paginationData?.totalItems }}</span>
                <span class="info-description">Lorem, ipsum dolor.</span>
              </div>
            </div>
          </div>

          <table class="referral-history-container">
  <thead>
    <tr class="referral-history-header">
      <th class="referral-column-1">User</th>
      <th class="referral-column">Registered</th>
      <th class="referral-column">Deposited</th>
      <th class="referral-column">Wager Completed</th>
      <th class="referral-column">Bonus Amount</th>
      <th class="referral-column">Last Updated</th>
    </tr>
  </thead>
  <tbody>
    <tr
      v-for="(item, index) in referralHistory"
      :key="index"
      class="referral-history-row"
    >
      <td class="referral-column-1">
        <!-- <p class="referral-user-initials">
          {{ getInitials(item?.referred_user?.username) }}
        </p> -->
        <span class="referral-user-name">
          {{ item?.referred_user?.username }}
        </span>
      </td>

      <td class="referral-column">
        <span
          :class="{
            'completed-step': item?.steps_completed?.is_registered,
            'pending-step': !item?.steps_completed?.is_registered,
          }"
          >✔</span
        >
      </td>

      <td class="referral-column">
        <span
          :class="{
            'completed-step': item?.steps_completed?.is_deposited,
            'pending-step': !item?.steps_completed?.is_deposited,
          }"
          >✔</span
        >
      </td>

      <td class="referral-column">
        <span
          :class="{
            'completed-step': item?.steps_completed?.is_wager_completed,
            'pending-step': !item?.steps_completed?.is_wager_completed,
          }"
          >✔</span
        >
      </td>

      <td class="referral-column">{{ item?.bonus_amount }} {{item?.currency}} </td>

      <td class="referral-column">
        {{ formatDate(item?.updated_at) }}
      </td>

     
    </tr>
  </tbody>
</table>

        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  data() {
    return {
      Promotions: {},
      loading: false,
      activeTab: "refer",
      referralLink: "",
      referralHistory: [],
      userData: {},
    
    };
  },
  methods: {
    t(key) {
      return this.$t(key);
    },
    getInitials(username) {
      return username ? username.charAt(0).toUpperCase() : "";
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    copyReferralLink() {
      navigator.clipboard.writeText(this.referralLink).then(() => {
        toast("Referral link copied to clipboard!");
      });
    },
    shareOnFacebook() {
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        this.referralLink
      )}`;
      window.open(facebookUrl, "_blank");
    },
    shareOnTwitter() {
      const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        this.referralLink
      )}`;
      window.open(twitterUrl, "_blank");
    },
    shareOnWhatsApp() {
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
        this.referralLink
      )}`;
      window.open(whatsappUrl, "_blank");
    },
    shareOnLinkedIn() {
      const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        this.referralLink
      )}`;
      window.open(linkedInUrl, "_blank");
    },
    shareOnTelegram() {
      const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
        this.referralLink
      )}`;
      window.open(telegramUrl, "_blank");
    },
    async fetchReferData() {
      this.loading = true;
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/promotion/get-all-promotion-user?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
          }
        );

        // Parse the response JSON
        const data = await response.json();
        // Save the retrieved user data
        const responseData = data?.promotions;

        const referralBonusPromotion = responseData.find(
          (promo) => promo.category === "referral_bonus"
        );

        console.log(responseData, "refferalbonus");
        this.Promotions = referralBonusPromotion; // Corrected variable name
        this.loading = false;
      } catch (error) {
        console.error("Error fetching user data:", error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async fetchUserData() {
      try {
        // Retrieve tokens from localStorage
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");
        // Check if tokens exist
        if (!userToken || !usernameToken) {
          console.error("User tokens not found in localStorage");
          return;
        }
        // Make GET request to the API endpoint
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/user/get-single-user?site_auth_key=${this.SITEAUTHKEY}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token: userToken,
              usernametoken: usernameToken,
            },
          }
        );

        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        // Parse the response JSON
        const userData = await response.json();
        // Save the retrieved user data
        this.userData = userData?.data;
        // console.log("User data during in header:", userData);
        console.log(userData?.data, "userData 123");
        this.referralLink = `https://demo.bitleon.com?refer_code=${this.userData?.referral_code}`;
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    async ReferralHistory() {
      // Retrieve tokens from localStorage
      const userToken = localStorage.getItem("userToken");
      const usernameToken = localStorage.getItem("usernameToken");

      // Check if tokens exist
      if (!userToken || !usernameToken) {
        console.error("User tokens not found in localStorage");
        return;
      }

      this.loading = true;
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/referral/get-all-referral-by-user?site_auth_key=${this.SITEAUTHKEY}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token: userToken,
              usernametoken: usernameToken,
            },
          }
        );

        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        // Parse the response JSON
        const data = await response.json();
        // Save the retrieved user data
        const responseData = data?.data;
        console.log(responseData, "responseData");

        this.referralHistory = responseData; // Corrected variable name
        this.loading = false;
      } catch (error) {
        console.error("Error fetching user data:", error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchReferData();
    this.ReferralHistory();
    this.fetchUserData();
  },
};
</script>
<style scoped>
.referral-container {
  max-width: 700px;
  margin: auto;
  margin-top: 50px;
  color: white;
  background: #3b3955;
  border-radius: 8px;
  padding: 16px;
}

.header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
}

.header button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  width: 130px;
  border-radius: 8px;
  background: #1b1928;
}

.header button.active {
  background-color: #01bd71;
}

.content {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

/* Refer Section Styles */
.banner {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 12px;
}

.how-it-works {
  text-align: left;
  margin-bottom: 30px;
}

.how-it-works ol {
  list-style: none;
  padding-left: 0;
}

.how-it-works li {
  margin-bottom: 15px;
  font-size: 18px;
}

.how-it-works span {
  font-weight: bold;
  display: block;
}

.step-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.referral-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.referral-input {
  width: 60%;
  margin: 0px;
  background-color: #1b1928;
  padding: 10px;
  color: white;
  margin-right: 10px;
  border-radius: 5px;
  border: none;
}

.referral-link button {
  padding: 6px;
  background-color: #23d366;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.social-share {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-share button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.facebook {
  background-color: #4267b2;
  color: #fff;
}
.twitter {
  background-color: #1da1f2;
  color: #fff;
}
.whatsapp {
  background-color: #25d366;
  color: #fff;
}
.linkedin {
  background-color: #0077b5;
  color: #fff;
}
.telegram {
  background-color: #0088cc;
  color: #fff;
}

/* Refer History Section Styles */
.refer-history {
  text-align: center;
}

.referral-wallet {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: -20px;
}
.referral-history {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.title {
  font-weight: bold;
  font-family: serif;
  font-size: 24px;
}

.subtitle {
  font-size: 9px;
  margin-top: -20px;
}

.referral-box {
  display: flex;
  gap: 6px;
  width: 100%;
  margin: auto;
}

.referral-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #1b1928;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  height: 120px;
  padding: 4px;
}

.referral-image .image {
  height: 30px;
  width: 30px;
}

@media (min-width: 768px) {
  .referral-image .image {
    height: 60px;
    width: 60px;
  }
  
}

.referral-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.referral-info .info-title {
  font-size: 12px;
  font-weight: 500;
}
.info-count {
}

.info-description {
  font-size: 9px;
  font-weight: 600;
}

.referral-info .info-count {
  font-size: 24px;
  font-weight: bold;
}

@media (min-width: 768px) {
  .referral-info .info-title {
    font-size: 16px;
  }
  .referral-info .info-count {
    font-size: 36px;
  }
  .referral-history-container {
    width:100%;

    display: block;
    overflow-x: auto;
    white-space: nowrap;
    overflow-y:scroll;
    height:100vh;
  }

}

@media (min-width: 768px) {
  .info-description {
    font-size: 12px;
  }
}

.history-title {
  font-weight: bold;
  font-family: serif;
  font-size: 18px;
  margin-top: 32px;
}

.history-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
  width: 100%;
}

.history-card {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  align-items: center;
  background: #1b1928;
  border-radius: 12px;
}

.history-user {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-initials {
  height: 50px;
  width: 50px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: pink;
  border-radius: 8px;
}

.user-info .user-name {
  font-weight: 600;
  font-size: 16px;
}

.user-info .user-date {
  font-size: 12px;
}
.user-date {
  margin-left: 10px;
}

.history-status .status-image {
  width: 30px;
  height: 30px;
}

.how-it-works-container {
  margin-top: 24px;
}

.how-it-works-title {
  color: #ffde01;
  display: flex;
  align-items: center;
  gap: 0px;
  font-weight: bold;
}

.steps-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
}

.step-item {
  display: flex;
  align-items: center;
  gap: 20px;
}

.step-number {
  height: 40px;
  width: 40px;
  background-color: #dfe4e8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.step-number.yellow {
  color: green;
}

.step-number.orange {
  color: #ff7f00;
}

.step-number.blue {
  color: #007bff;
}

.step-number.green {
  color: #28a745;
}

.step-title {
  font-weight: bold;
}

.step-description {
  font-size: 12px;
}

.highlighted-text {
  color: #28a745;
  font-weight: bold;
}

.reward-icon {
  height: 30px;
  width: 30px;
}

.reward-small-icon {
  height: 20px;
  width: 20px;
}
/* Referral History Table Styles */
.referral-history-container {
  width:100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
  margin-top:30px;
  overflow-x: auto;


}

.referral-history-header {
  background-color: #01BD71;
  text-align: left;
  text-wrap:nowrap;
  font-size: 16px;
}

.referral-column-1{
  width: 120px;
 
  font-size: 14px;
}
.referral-column{
  width: 120px;
  font-size: 14px;


}
.referral-history-header th {
  padding: 4px 10px;
  font-weight: bold;
  color: #333;
  border-bottom: 2px solid #dee2e6;
}

.referral-history-row td {
  padding: 10px 15px;
  border-bottom: 1px solid #dee2e6;
  text-align: left;
  vertical-align: middle;
}

.referral-user-initials {
  font-weight: bold;
  background-color: #007bff;
  color: #fff;
  padding: 5px 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.referral-status-image {
  width: 20px;
  height: 20px;
}

.completed-step {
  color: green;
}

.pending-step {
  color: red;
}

/* Responsive Design */
@media (max-width: 768px) {
  .referral-history-container {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    width:100%;
    overflow-y:scroll;
    height:60vh
  }
  .referral-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:center;
  gap:5px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.referral-input{
  width:100%
}
.social-share {
  display: flex;
  overflow: scroll;
  justify-content: flex-start;
  gap: 10px;
  margin-top:30px
}

.social-share button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.referral-container {
  max-width: 700px;
  margin: auto;
  margin-top: 0px;
  color: white;
  background: #3b3955;
  border-radius: 0px;
  padding: 4px;
  min-height: 90vh;
}
.referral-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #1b1928;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  height: 70px;
  padding: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.referral-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0px;

}
.header{
  padding-top:15px
}

.title {
  font-weight: bold;
  font-family: serif;
  font-size: 19px;
}
.subtitle {
  font-size: 9px;
  margin-top: -10px;
}

  .referral-history-header,
  .referral-history-row {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .referral-history-header th,
  .referral-history-row td {
    text-align: center;
    padding: 8px;
  }

  .referral-user-initials {
    margin: 0 auto 10px;
    display: block;
  }

  .referral-user-name {
    display: block;
    text-align: center;
    margin-top: 5px;
  }
}

@media (max-width: 480px) {
  .referral-history-header th,
  .referral-history-row td {
    padding: 6px;
  }

  .referral-status-image {
    width: 16px;
    height: 16px;
  }
  
}

</style>
