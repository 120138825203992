function formatDateTime(isoString) {
    const date = new Date(isoString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

export {formatDateTime}

// encoding.js

// Function to encode data to Base64
export function encodeData(data) {
    try {
      return btoa(JSON.stringify(data)); // Base64 encode the object
    } catch (error) {
      console.error("Encoding failed:", error);
      return null;
    }
  }
  
  // Function to decode Base64 data
  export function decodeData(encodedData) {
    try {
      return JSON.parse(atob(encodedData)); // Base64 decode
    } catch (error) {
      console.error("Decoding failed:", error);
      return "Invalid data"; // Handle decoding error
    }
  }
  