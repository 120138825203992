<template>
  <div class="c-vip-page">
    <!-- Background Picture -->
    <picture>
      <source
        media="(max-width: 520px)"
        srcset="https://d3oqh5ecy4r3n8.cloudfront.net/img/bg-mobile.d42dc10.png"
        class="c-vip-page-img"
      />
      <source
        media="(min-width: 521px)"
        srcset="
          https://d3oqh5ecy4r3n8.cloudfront.net/img/bg-desktop.302b433.png
        "
        class="c-vip-page-img"
      />
      <img
        src="https://d3oqh5ecy4r3n8.cloudfront.net/img/bg-desktop.302b433.png"
        alt="VIP Starz Club background"
        class="c-vip-page-img is-desktop"
      />
    </picture>
    <div class="c-vip-page-container">
      <header class="c-vip-page-header">
        <h1 class="c-vip-page-header__title">VIP STARZ CLUB</h1>
        <span class="c-vip-page-header__text"
          >The Most Exclusive VIP Program Ever</span
        >
      </header>

      <!-- Section Rendering Cards Dynamically -->
      <section class="c-vip-page-content">
        <!-- Check if data is loading -->
        <div v-if="loading" class="loading">Loading VIP Benefits...</div>

        <!-- Map over VIP benefits -->
        <div
          v-else
          v-for="(card, index) in vipData"
          :key="index"
          class="c-vip-page-content__card"
          :style="{ background: card.bg_gradient, color: card?.color }"
        >
          <span class="c-vip-page-content__card-bg"></span>

          <!-- Render Vue-compatible icon here -->
          <component :is="card.icon" class=""></component>
          <span class="c-vip-page-content__card-title">{{
            card.level_name
          }}</span>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              font-weight: 900;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                color: white;
              "
            >
             <div style="display: flex;align-items: center;gap: 4px ;font-size: 18px; margin-top: 20px; font-weight: 900">
                Wager Point
                <p 
      style="display: flex;align-items: center;justify-content: center ;cursor: pointer;background-color: white; color: gray; font-size: 16px; height: 20px; width: 20px; border-radius: 50%;">
      ℹ
    </p>
    </div>
              <p style="font-size: 20px; margin-top: -15px;color: #01BD71;">
                {{ card?.wager_points }}
              </p>
            </div>

            <div
              style="
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                color: white;
              "
            >
              <div style="display: flex;align-items: center;gap: 4px ;font-size: 18px; margin-top: 20px; font-weight: 900">
                Deposit Point
                <p 
      style="display: flex;align-items: center;justify-content: center ;cursor: pointer;background-color: white; color: gray; font-size: 16px; height: 20px; width: 20px; border-radius: 50%;">
      ℹ
    </p>
    </div>
              <p style="font-size: 20px; margin-top: -15px;color: #01BD71;">
                {{ card?.deposit_points }}
              </p>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 80px;
              width: 90%;
              color: white !important;
              box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
                rgba(0, 0, 0, 0.23) 0px 6px 6px;
              border-radius: 6px;
            "
          >
            <p style="font-size: 20px; margin-top: 20px; font-weight: 900">
              Total Bonus Reward 
            </p>
            <p style="font-size: 20px; margin-top: -20px; font-weight: 900;color: #01BD71;">
              {{ card?.total_bonus_reward }}
            </p>
          </div>
          <div style="display: flex; flex-direction: column; gap: 7px; width: 100%;">

          <div
            style="display: flex; justify-content: space-between; width: 100%"
          >
            <p style="font-size: 16px; font-weight: 800; color: white">
              Check percent
            </p>
            <p class="c-vip-page-content__card-text">
              {{ card.cashback_percent }}
            </p>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: -30px;
            "
          >
            <p style="font-size: 16px; font-weight: 800; color: white">
              Withdraw process time
            </p>
            <p class="c-vip-page-content__card-text">
              {{ card.withdraw_process_time }}
            </p>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: -30px;
            "
          >
            <p style="font-size: 16px; font-weight: 800; color: white">
              Bonus Wager Multiplier
            </p>
            <p class="c-vip-page-content__card-text">
              {{ card.bonus_wager_multiplier }}
            </p>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: -30px;
            "
          >
            <p style="font-size: 16px; font-weight: 800; color: white">
              Dedicated support_24_7
            </p>

            <p 
  class="c-vip-page-content__card-text" 
  :style="`display: flex; align-items: center; justify-content: center; height: 25px; width: 25px; border-radius: 50%; border: 2px solid ${card.dedicated_support_24_7 ? 'green' : 'red'};`"
>
  <span v-if="card.dedicated_support_24_7" style="color: white">✔</span>
  <span v-else style="color: white">✖</span>
</p>


          </div>

          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: -30px;
            "
          >
            <p style="font-size: 16px; font-weight: 800; color: white">
              Increased withdrawal limits
            </p>

            <p class="c-vip-page-content__card-text" 
  :style="`display: flex; align-items: center; justify-content: center; height: 25px; width: 25px; border-radius: 50%; border: 2px solid ${card.increased_withdrawal_limits ? 'green' : 'red'};`"
            
            >
              <span v-if="card.increased_withdrawal_limits" style="color: white"
                >✔</span
              >
              <span v-else style="color: white">✖</span>
            </p>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: -30px;
            "
          >
            <p style="font-size: 16px; font-weight: 800; color: white">
              Priority withdrawal queue
            </p>

            <p class="c-vip-page-content__card-text" 
            :style="`display: flex; align-items: center; justify-content: center; height: 25px; width: 25px; border-radius: 50%; border: 2px solid ${card.priority_withdrawal_queue ? 'green' : 'red'};`"
            >
              <span v-if="card.priority_withdrawal_queue" style="color: white"
                >✔</span
              >
              <span v-else style="color: white">✖</span>
            </p>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: -30px;
            "
          >
            <p style="font-size: 16px; font-weight: 800; color: white">
              VIP Manager
            </p>

            <p class="c-vip-page-content__card-text" key=""
            
             :style="`display: flex; align-items: center; justify-content: center; height: 25px; width: 25px; border-radius: 50%; border: 2px solid ${card.vip_manager ? 'green' : 'red'};`"
            >
              <span v-if="card.vip_manager" style="color: white">✔</span>
              <span v-else style="color: white">✖</span>
            </p>
          </div>
        </div>

        </div>
      </section>
    </div>
    <footerBottom />
  </div>
</template>

<script>
import {
  faShieldAlt,
  faStar,
  faGem,
  faCrown,
  faHeart,
  faThumbsUp,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import footerBottom from "../parts/footer.vue";

export default {
  name: "VIP",
  components: {
    footerBottom,
    FontAwesomeIcon, // Register FontAwesome component
  },
  data() {
    return {
      loading: true,
      vipData: [],
    };
  },
  methods: {
    async fetchVipData() {
      try {
        const url = `${process.env.VUE_APP_API_KEY}/api/vip-level/get-vip-level?site_auth_key=${process.env.VUE_APP_API_SITE_AUTH_KEY}`;
        const response = await fetch(url);
        const data = await response.json();
        this.vipData = data?.data.map((level) => ({
          ...level,
          icon: this.getIconForLevel(level.level_name), // Dynamically get the icon
        }));
        this.loading = false;
      } catch (error) {
        console.error("Error fetching VIP data:", error);
        this.loading = false;
      }
    },
    getIconForLevel(levelName) {
      switch (levelName) {
        case "Bronze":
          return faShieldAlt;
        case "Silver":
          return faStar;
        case "Gold":
          return faGem;
        case "Platinum":
          return faCrown;
        case "Diamond":
          return faHeart;
        case "Ruby":
          return faThumbsUp;
        case "Emerald":
          return faRocket;
        default:
          return faShieldAlt; // Default icon
      }
    },
  },
  mounted() {
    this.fetchVipData();
  },
};
</script>

<style scoped>
.c-vip-page {
  background-color: #1b1928;
  padding: 59vw 0 0;
  position: relative;
}

@media (min-width: 478px) {
  .c-vip-page {
    padding: 25vw 0 0;
  }
}

@media (min-width: 1650px) {
  .c-vip-page {
    padding: 354px 0 0;
  }
}

.c-vip-page-img {
  height: auto;
  left: 50%;
  max-width: 100%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 1650px;
}

@media (min-width: 1650px) {
  .c-vip-page-img {
    top: -56px;
  }
}

.c-vip-page-container {
  margin: 0 auto;
  max-width: 1420px;
  padding: 0 45px;
  position: relative;
  width: 100%;
  z-index: 1;
}

@media (max-width: 1024px) {
  .c-vip-page-container {
    padding: 0 15px;
  }
}

.c-vip-page-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 14.5vw;
  text-align: center;
}

@media (min-width: 478px) {
  .c-vip-page-header {
    padding: 0 0 4.6vw;
  }
}

@media (min-width: 1650px) {
  .c-vip-page-header {
    padding: 0 0 75px;
  }
}

.c-vip-page-header__title {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(#e5b400, #ffd22f);
  -webkit-background-clip: text;
  background-clip: text;
  background-origin: content-box;
  color: transparent;
  font-size: 36px;
  font-weight: 900;
  line-height: 1;
  margin: 0 0 10px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .c-vip-page-header__title {
    font-size: 52px;
  }
}

@media (min-width: 1024px) {
  .c-vip-page-header__title {
    font-size: 64px;
  }
}

.c-vip-page-header__text {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 42px;
}

@media (min-width: 768px) {
  .c-vip-page-header__text {
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  .c-vip-page-header__text {
    font-size: 24px;
  }
}

.c-vip-page-content {
  grid-gap: 15px;
  display: grid;
  padding: 0 0 40px;
}

@media (min-width: 768px) {
  .c-vip-page-content {
    grid-template-columns: repeat(12, 1fr);
  }
}

@media (min-width: 1024px) {
  .c-vip-page-content {
    grid-gap: 50px;
    padding: 0 0 100px;
  }
}

.c-vip-page-content__card {
  align-items: center;
  background-color: #2b2740;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 20px;
  position: relative;
}

@media (min-width: 768px) {
  .c-vip-page-content__card {
    grid-column: span 4;
  }

  .c-vip-page-content__card:nth-child(n + 4) {
    grid-column: span 6;
  }
}

@media (min-width: 1024px) {
  .c-vip-page-content__card {
    padding: 30px;
  }
}

.c-vip-page-content__card:first-child .c-vip-page-content__card-bg {
  background-color: rgba(255, 0, 92, 0.1);
}

.c-vip-page-content__card:nth-child(2) .c-vip-page-content__card-bg {
  background: rgba(255, 214, 0, 0.1);
}

.c-vip-page-content__card:nth-child(3) .c-vip-page-content__card-bg {
  background: rgba(255, 230, 0, 0.1);
}

.c-vip-page-content__card:nth-child(4) .c-vip-page-content__card-bg {
  background: rgba(0, 255, 87, 0.1);
}

.c-vip-page-content__card:nth-child(5) .c-vip-page-content__card-bg {
  background: rgba(0, 255, 209, 0.1);
}

.c-vip-page-content__card-bg {
  border-radius: 50%;
  filter: blur(80px);
  height: 65%;
  left: 0;
  position: absolute;
  top: -10%;
  width: 100%;
}

.c-vip-page-content__img {
  height: 128px;
  width: auto;
}

.c-vip-page-content__card-title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
}

@media (min-width: 1024px) {
  .c-vip-page-content__card-title {
    line-height: 1.75;
  }
}

.c-vip-page-content__card-text {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
}

@media (min-width: 1024px) {
  .c-vip-page-content__card-text {
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
