



<template>
  <main>
    <div class="c-games-category"><!---->
      <div>
        <section data-cy="cy-games-sections-wrapper" class="games-sections-wrapper">
          <div data-cy="cy-games-sections" class="games-sections">
            <div data-cy="cy-games-sections__heading" class="games-sections__heading">
              <div data-cy="cy-games-sections__heading-box" class="games-sections__heading-box">
                <button class="c-button games-sections__heading-back has-icon color-light-purple size-m"
                  data-cy="cy-games-sections__heading-back" @click="goBack">
                  <font-awesome-icon :icon="['fas', 'arrow-left']" size="lg" />
                </button> <!---->
                <h1 data-cy="cy-games-sections__title" class="games-sections__title">
                  Table Games
                </h1> <!---->
              </div>

              
            </div>
            <div>
              <div data-cy="cy-games-block" class="game-box ">
                <div v-for="game in games" :key="game._id" class="">
              <a :href="`/table-games/${game.gameProviderId}/${game.gameID}?mode=player`" class="c-game-container">
                <!-- Game badges -->
                <div class="c-game-badges">
                  <div class="c-game-badges-collection"></div>
                  <span class="game-badge-separator"></span>
                  <!-- Use first character of provider's name to construct image source -->
                  <span class="game-badge-provider is-bagde-hover">
                    <span class="game-badge-provider__overlay"></span>
                    <img style="width: 10px; height:10px ;" src="https://companieslogo.com/img/orig/EVO.ST-913bcbb0.png?t=1684219168" :alt="game.provider" class="game-badge-provider__icon">
                  </span>
                </div>
                <!-- Game box with image -->
                <div class="c-game-box">
                  <img :src="game.gameInfos[0].gameIconUrl" :alt="game.gameInfos[0].gameName" class="c-game-box__image" style="height:230px;width:100%">
                </div>
              </a>
            </div>
              </div> <!---->
              <div data-cy="cy-games-block__footer" class="games-block__footer"><!----></div>
            </div>
          </div>
          <div class="load-more-container">
      <button style="margin-top: 10px" @click="loadMore" v-if="currentPage < totalPages" class="load-more-button">
        Load More
      </button>
    </div>
        </section>
      </div>
      <section class="c-site-description">
        <div class="c-site-description__section">
          <h2>
            Table Games
          </h2>
          <p>
            <span>
              Table games combine the true essence of gambling with pure excitement and thrill. And when it comes to
              betting on the best in the industry, bitleon has you covered like a Roulette layout on payday.
            </span>
            <a :style="{ display: readMoreDataButton }" class="readmore" @click="readMore"> Read more </a>
          </p>
          <div :style="{ display: readMoreData }">
            <p>
              With over 250 table games to choose from, bitleon is your number one destination when it comes to wagering
              on tables. We offer only the highest quality games from the world’s leading providers such as Authentic
              Gaming, Belatra, Betsoft, BGaming, Platipus, Evoplay Entertainment, Evolution Gaming, Vivo Gaming, iSoftBet,
              NetEnt, and PGSoft.</p>
            <p>Cards, wheels, dice, and everything nice can be found on our premium selection of table games. And if
              you’re looking to play with cryptos then take a seat, there's a whole stack of table games available in
              multiple cryptocurrencies.</p>
            <p>We take immense pride in bringing top-quality casino entertainment to our players, and our Table Games menu
              is nothing short of spectacular!
            </p>
          </div>
        </div>
        <div class="c-site-description__section" :style="{ display: readMoreData }">
          <h2>Play Your Way with Multiple Currencies</h2>
          <div>
            <p>It’s no secret that our games are available in multiple currencies - we shout it loud and proud for the
              whole world to hear after all. Because at bitleon, we want you to play <strong>YOUR WAY</strong>. We’ve
              gone to great lengths to ensure that you can enjoy playing many of your favorite games in various fiat and
              cryptocurrencies.</p>
            <p><strong>Go old school with fiat currencies:</strong> Euro (EUR), US Dollars (USD), Canadian Dollar (CAD),
              Australian Dollar (AUD), New Zealand Dollar (NZD), Japanese Yen (JPY), Renminbi (RMB), Polish Złoty (PLN),
              Russian Ruble (RUB), Norwegian Krone (NOK).</p>
            <p><strong>Unchain the game with cryptocurrencies:</strong> Bitcoins (BTC), Bitcoin Cash (BCH), Ethereum
              (ETH), Dogecoin (DOG), Tether (USDT) and Litecoin (LTC).</p>
          </div>
        </div>
        <div class="c-site-description__section" :style="{ display: readMoreData }">
          <h2>Roulette</h2>
          <div>
            <p>Take your pick, we have them all! French, European, and American Roulette games are served fresh and hot
              every day. Add a little spice with bonus Roulette games too, such as <a
                href="/table-games/lucky-roulette">Lucky Roulette</a> or <a
                href="/table-games/golden-chip-roulette">Golden Chip Roulette</a> for some fiery features and extra
              payouts.</p>
          </div>
        </div>
        <div class="c-site-description__section" :style="{ display: readMoreData }">
          <h2>Blackjack</h2>
          <div>
            <p>Hit the Blackjack tables at bitleon and find nothing but magic! Variations of Blackjack from around the
              world are available at the click of a button. We have some of the most exciting bonus-added Blackjack games
              ever made too, such as <a href="/slots/blackjack-lucky-sevens">Blackjack Lucky Sevens</a> or <a
                href="/table-games/double-exposure">Double Exposure</a> if you’d like a glimpse at both the dealer’s
              cards.</p>
          </div>
        </div>
        <div class="c-site-description__section" :style="{ display: readMoreData }">
          <h2>Poker</h2>
          <div>
            <p>The massive pot of Poker games just keeps getting bigger at bitleon. Rake in the winnings with our
              world-class selection of Poker in many varieties from the most prominent providers around. <a
                href="/table-games/texas-holdem">Texas Hold’em</a>, <a href="/table-games/oasis-poker">Oasis Poker</a>, <a
                href="/table-games/lucky-roulette">Jacks or Better</a>, <a href="/table-games/caribbean-poker">Caribbean
                Poker</a>, and many more legendary Poker games are waiting for your winning hand.</p>
          </div>
        </div>
        <div class="c-site-description__section" :style="{ display: readMoreData }">
          <h2>Baccarat</h2>
          <div>
            <p>When it comes to Baccarat, bitleon is a natural nine! Our incredible spread of Baccarat games comes second
              to none. Try your hand at our vast selection such as Baccarat by <a
                href="/table-games/baccarat-bgaming">BGaming</a> or <a href="/table-games/baccarat-netent">NetEnt</a> for
              something classic. If you have an exotic taste then <a href="/slots/baccarat-777">Baccarat 777</a> or <a
                href="/table-games/big-win-baccarat">Big Win Baccarat</a> should satisfy the craving.</p>
          </div>
        </div>
        <div class="c-site-description__section" :style="{ display: readMoreData }">
          <h2>Other Games</h2>
          <div>
            <p>Table games are forever evolving in the world of online gaming. We have many non-traditional table games
              that have been created by some of the best and brightest minds in the industry. If you’re looking for
              something weird and wonderful, or just a little different to anything else, then look no further, bitleon
              has it all!</p>
            <p>Enjoy the tables folks. And remember to Dream Big, Win Bigger at bitleon!</p>
          </div>
        </div>


      </section>
    </div>
  </main>
  <div>
    <footerBottom />
  </div>
</template>

<script>
import axios from 'axios'; // Import axios for making HTTP requests
import footerBottom from '../parts/footer.vue'; // Import footer component

export default {
  name: 'Table Games',
  data() {
    return {
      readMoreData: 'none',
      readMoreDataButton: 'inline-flex',
      games: [], // Array to store games
      currentPage: 1, // Track the current page
      totalPages: null // Track total pages from pagination
    };
  },
  props: {
    msg: String
  },
  components: {
    footerBottom
  },
  mounted() {
    // Call the API to fetch initial games
    this.fetchGames();
  },
  methods: {
    t(key) {
      return this.$t(key);
    },
    // Function to fetch games from API
    async fetchGames() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_KEY}/api/game/get-game-by-game-type`, {
          params: {
            status: true,
            page: this.currentPage,
            game_type:204,
            limit: 28,
            site_auth_key:process.env.VUE_APP_API_SITE_AUTH_KEY
          }
        });
        if (response.status === 200 && response.data.success) {
          // Update games array with new data
          this.games = [...this.games, ...response.data.data];
          this.totalPages = response.data.pagination.totalPages;
        } else {
          console.error('Failed to fetch games:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    },
    // Function to load more games
    loadMore() {
      if (this.currentPage < this.totalPages) {
        // Increment current page and fetch more games
        this.currentPage++;
        this.fetchGames();
      }
    },
    readMore() {
      this.readMoreData = 'block';
      this.readMoreDataButton = 'none';
    },
    goBack() {
      this.$router.back();
    }
  }
};
 


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.load-more-container {
  text-align: center;
}

.load-more-button {
  background-color: #1e90ff; /* Adjust color as needed */
  border: none;
  color: white;
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.load-more-button:hover {
  background-color: #007bff; /* Adjust hover color as needed */
}

.load-more-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-top: 2px solid rgba(255, 255, 255, 0.7);
  border-right: 2px solid rgba(255, 255, 255, 0.7);
  animation: loadMoreAnimation 0.8s infinite alternate;
}

@keyframes loadMoreAnimation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(180deg);
  }
}
.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {
  .c-swiper-wrapper {
    padding: 0 15px
  }
}

.is-mobile .c-swiper-wrapper,
.is-tablet .c-swiper-wrapper {
  padding: 0
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2
}

@media(max-width:1024px) {
  .c-swiper-header {
    margin: 0 0 11px
  }
}

.is-mobile .c-swiper-header,
.is-tablet .c-swiper-header {
  padding: 0 45px
}

@media(max-width:1024px) {

  .is-mobile .c-swiper-header,
  .is-tablet .c-swiper-header {
    padding: 0 15px
  }
}

.c-swiper-header__box {
  align-items: center;
  display: flex
}

.c-swiper-container {
  overflow: hidden;
  position: relative
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px)
}

.c-swiper::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0
}

.c-swiper.scroll-enabled {
  overflow: scroll
}

@media(max-width:1024px) {
  .c-swiper {
    margin-left: -5px;
    width: calc(100% + 10px)
  }
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px
}

.is-mobile .c-swiper,
.is-tablet .c-swiper {
  margin: 0;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {

  .is-mobile .c-swiper,
  .is-tablet .c-swiper {
    padding: 0 15px
  }
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%
}

.c-swiper-item.is-placeholder {
  width: 6.65%
}

.is-mobile .c-games-swiper-section .c-swiper-item,
.is-tablet .c-games-swiper-section .c-swiper-item {
  justify-content: flex-start;
  min-width: 134px;
  padding: 5px 10px 5px 0;
  width: 134px
}

.is-mobile .c-games-swiper-section .c-swiper-item:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item:last-child {
  min-width: 169px;
  padding: 5px 45px 5px 0;
  width: 169px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 139px;
    padding: 5px 15px 5px 0;
    width: 139px
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins {
  min-width: 305px;
  width: 305px
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  min-width: 340px;
  width: 340px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
    min-width: 310px;
    width: 310px
  }
}

@media(max-width:478px) {
  .is-mobile .c-games-swiper-section .c-swiper-item {
    min-width: 34%;
    width: 34%
  }

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 31.375%;
    padding-right: 0;
    width: 31.375%
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins {
  max-width: 305px;
  min-width: auto;
  width: 64.35%
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  max-width: 295px;
  min-width: auto;
  padding-right: 0;
  width: 61.45%
}

.c-swiper-side-gradient {
  height: calc(100% - 20px);
  position: absolute;
  top: 10px;
  z-index: 4
}

@media(max-width:1024px) {
  .c-swiper-side-gradient {
    height: calc(100% - 10px);
    top: 5px
  }
}

.c-swiper-side-placeholder {
  background: linear-gradient(90deg, #1b1928, rgba(27, 25, 40, 0));
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s ease-in;
  width: .5%
}

.c-swiper-side-placeholder.is-visible {
  opacity: 1
}

.c-swiper-side-navigation {
  background: linear-gradient(90deg, rgba(27, 25, 40, 0) 40%, #1b1928);
  pointer-events: none;
  right: 0;
  transition: all .25s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 5%
}

.c-swiper-side-navigation.should-hide-gradient {
  background: none
}

.c-swiper-side-navigation .c-swiper-navigation {
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;
  width: 100%
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  margin-right: 0;
  opacity: 0;
  pointer-events: all;
  transition: opacity .25s ease-in
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow:first-child {
  margin-top: 10px
}

.c-swiper-container:hover .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  opacity: 1
}

.is-desktop .c-swiper-side-navigation,
.is-desktop .c-swiper-side-placeholder {
  bottom: 10px;
  height: calc(100% - 60px);
  top: auto
}

@media(max-width:1024px) {

  .is-desktop .c-swiper-side-navigation,
  .is-desktop .c-swiper-side-placeholder {
    bottom: 5px;
    height: calc(100% - 50px)
  }
}

.c-game-badges {
  position: absolute;
  justify-content: space-between;
  top: 15px;
  right: 7%;
  display: flex;
  width: 85%;
}

.c-game-badges-collection {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  z-index: 5;
}

.c-game-badges span {
  flex: 0 0 auto;
  margin-right: 3px;
}

.c-game-badge.is-exclusive {
  background-color: #00bd71;
}

.c-game-badges span:last-child {
  margin-right: 0;
}

.c-game-badges .c-game-badge__text {
  margin: 0;
  /* position: relative;
    top: 15px; */
}

.c-game-badge {
  fill: #fff;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  cursor: default;
  display: flex;
  font-size: 11px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0 4px;
  position: relative;
}

.c-swiper-container .game .c-game-badges .game-badge-provider {
  display: none;
}

.c-swiper-container .c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

@media (hover: hover) {
  .c-game-container:hover .c-game-box__image {
    transform: scale3d(1.05, 1.05, 1);
    z-index: 4;
    position: relative;
  }

  .c-game-container:hover {
    background: #3c3955;
  }
}

.c-swiper-container .game .c-game-box img {
  border-radius: 10px;
}

.c-swiper-item-games {
  display: flex;
}

.c-game-badge.is-bs-original {
  background-color: #e31351;
  /* margin-right: 5px; */
}

.c-game-badge__iconb {
  width: 0;
}

.sprite-icons {
  width: 0;
}

.c-game-badge.is-jackpot {
  background: #854dff;
  color: #fff;
}

.c-game-badge.is-new {
  background-color: #e31351;
}

.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%;
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.games-sections__title {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 15px 0 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: uppercase;
}

.games-sections__title.is-clickable {
  cursor: pointer;
}

.homepage-variation-2 .games-sections__title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: capitalize;
}

.c-swiper-navigation {
  align-items: center;
  display: flex;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.c-swiper-container {
  overflow: hidden;
  position: relative;
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px);
}

.c-swiper.scroll-enabled {
  overflow: scroll;
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__body {
  align-items: center;
  aspect-ratio: 1.85;
  background-color: hsla(0, 0%, 100%, .05);
  display: flex;
  justify-content: center;
}

.is-variation-2 .c-provider-card__body {
  aspect-ratio: 2/1;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.bs-c-homepage-v2-title {
  text-align: left;
}

.buy-img {
  height: 20px;
}

.games-block__button {
  margin: auto;
}

.c-site-description__section {
  text-align: left;
}

.c-game-badges .game-badge-provider {
  display: none;
}

.c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

.c-game-container .c-game-box img {
    border-radius: 10px;
}
</style>
