<template>
    <div class="wallet-component">
      <div v-if="loading" class="loading-container">
        <div class="spinner"></div>
      </div>
  
      <div v-else>
        <div class="wallet-grid">
          <div v-for="(wallet, index) in wallets" :key="index" class="wallet-item">
            <div class="wallet-info">
              <span class="wallet-icon">{{ wallet.icon }}</span>
              <span class="wallet-amount">{{ wallet.amount }}</span>
            </div>
            <div class="wallet-type">{{ wallet.type }}</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        userData: {},
        loading: false,
        userToken: localStorage.getItem("userToken"),
        usernameToken: localStorage.getItem("usernameToken"),
        wallets: [
          { type: 'Wallet Amount', icon: '💵', amount: '0.00' },
          { type: 'Total Deposit', icon: '🏦', amount: '0.00' },
          { type: 'Total Withdraw', icon: '💸', amount: '0.00' },
          { type: 'Total Bonus', icon: '🎉', amount: '0.00' },
          { type: 'Wager Left', icon: '⚖️', amount: '0.00' },
          { type: 'Total Wager', icon: '🎲', amount: '0.00' },
          { type: 'Referral Count', icon: '👥', amount: '0' },
          { type: 'Total Referral Bonus', icon: '🏆', amount: '0.00' },
        ],
      };
    },
    methods: {
      async getUserData() {
        this.loading = true;
        const url = `${process.env.VUE_APP_API_KEY}/api/user/get-user-overview-details`;
  
        try {
          const response = await fetch(url, {
            method: 'GET', // Assuming POST is the correct method
            headers: {
              "Content-Type": "application/json",
              "token": this.userToken,
              "usernametoken": this.usernameToken,
            },
          });
  
          const data = await response.json();
          this.loading = false;
          this.userData = data.data;
          this.updateWallets();
        } catch (error) {
          this.loading = false;
          console.error("Error fetching user data:", error);
        }
      },
      updateWallets() {
        this.wallets = [
          { type: 'Wallet Amount', icon: '💵', amount: this.userData.walletAmount || '0.00' },
          { type: 'Total Deposit', icon: '🏦', amount: this.userData.totalDeposit || '0.00' },
          { type: 'Total Withdraw', icon: '💸', amount: this.userData.totalWithdraw || '0.00' },
          { type: 'Total Bonus', icon: '🎉', amount: this.userData.totalBonus || '0.00' },
          { type: 'Wager Left', icon: '⚖️', amount: this.userData.wagerLeft || '0.00' },
          { type: 'Total Wager', icon: '🎲', amount: this.userData.totalWager || '0.00' },
          { type: 'Referral Count', icon: '👥', amount: this.userData.referralCount || '0' },
          { type: 'Total Referral Bonus', icon: '🏆', amount: this.userData.totalReferralBonus || '0.00' },
        ];
      }
    },
    mounted() {
      this.getUserData();
    },
  };
  </script>
  
  <style scoped>
    .wallet-component {
        padding: 0.5em;
        display:flex;
        justify-content:center;
        align-items: center;
  }
  .wallet-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 6px;
    column-gap: 22px;
  }
  
  .wallet-item {
    padding: 10px;
    border: 1px solid #d3d3d3; /* Gray border */
    border-radius: 8px;
    background-color: var(--secondary-bg, #ffffff);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Light shadow */
    transition: all 0.2s;
  }
  
  .wallet-item:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);
  }
  
  .wallet-type {
    font-size: 12px; /* Smaller text */
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .wallet-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .wallet-amount {
    font-size: 14px; /* Smaller amount text */
    font-weight: bold;
  }
  
  .wallet-icon {
    font-size: 18px; /* Smaller icon */
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top-color: #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  </style>
  