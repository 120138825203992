



<template>
  <main>
    <div class="c-games-category"><!---->
      <div>
        <section data-cy="cy-games-sections-wrapper" class="games-sections-wrapper">
          <div data-cy="cy-games-sections" class="games-sections">
            <div data-cy="cy-games-sections__heading" class="games-sections__heading">
              <div data-cy="cy-games-sections__heading-box" class="games-sections__heading-box">
                <button class="c-button games-sections__heading-back has-icon color-light-purple size-m"
                  data-cy="cy-games-sections__heading-back" @click="goBack">
                  <font-awesome-icon :icon="['fas', 'arrow-left']" size="lg" />
                </button> <!---->
                <h1 data-cy="cy-games-sections__title" class="games-sections__title">
                  {{t(`Top`)}} {{t(`Live`)}} {{t(`Casino`)}} {{t(`Game`)}}
                </h1> <!---->
              </div>

              
            </div>
            <div>
              <div data-cy="cy-games-block" class="game-box ">
                <div v-for="game in games" :key="game._id" class="">
              <a :href="`/live-casino/${game.gameProviderId}/${game.gameID}?mode=player`" class="c-game-container">
                <!-- Game badges -->
                <div class="c-game-badges">
                  <div class="c-game-badges-collection"></div>
                  <span class="game-badge-separator"></span>
                  <!-- Use first character of provider's name to construct image source -->
                  <span class="game-badge-provider is-bagde-hover">
                    <span class="game-badge-provider__overlay"></span>
                    <img style="width: 10px; height:10px ;" src="https://companieslogo.com/img/orig/EVO.ST-913bcbb0.png?t=1684219168" :alt="game.provider" class="game-badge-provider__icon">
                  </span>
                </div>
                <!-- Game box with image -->
                <div class="c-game-box">
                  <img :src="game.gameInfos[0].gameIconUrl" :alt="game.gameInfos[0].gameName" class="c-game-box__image" style="height:230px;width:100%">
                </div>
              </a>
            </div>
              </div> <!---->
              <div data-cy="cy-games-block__footer" class="games-block__footer"><!----></div>
            </div>
          </div>
          <div class="load-more-container">
      <button style="margin-top: 10px" @click="loadMore" v-if="currentPage < totalPages" class="load-more-button">
        {{t(`Load More`)}}
      </button>
    </div>
        </section>
      </div>
      
      <section class="c-site-description">
  <div class="c-site-description__section">
    <h2>
      Live Casino
    </h2>
    <p>
      <span>
        With bitleon’s Live Casino, you can experience the thrill of a live game from the safety and comfort of
        your own home - or wherever you choose to play!
      </span>
      <a :style="{ display: readMoreDataButton }" class="readmore" @click="readMore"> Read more </a>
    </p>
    <div :style="{ display: readMoreData }">
      <p>bitleon works with leading live casino game providers such as Evolution Gaming, Vivo Gaming and Authentic
        Gaming. Thanks to these collaborations, you’ll be treated to an immersive experience made all the better
        thanks to High-Definition streaming, multiple camera angles and knowledgeable and experienced dealers - who
        aren’t too hard on the eyes, either.</p>
      <p>You won’t be left wanting when it comes to variety that’s for sure. We offer 150 Live Casino games
        including Blackjack, Roulette, Baccarat, Poker, Wheel games, Sic Bo, Dream Catcher and Top Card Football
        Studio. In addition, most of our Live Casino games are available 24/7.</p>
    </div>

  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Live Blackjack</h2>
    <div>
      <p>At bitleon, you’ll find interesting Live Casino variants such as Blackjack Party, First Person Blackjack,
        Infinite Blackjack and Salon Priveé Blackjack.</p>
      <p>Feeling like a big hitter who wants to wager a bit more each round? There’s also Blackjack VIP, which is
        perfect for anyone that has high roller ambitions.</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Live Roulette</h2>
    <div>
      <p>Most games play with a single-zero wheel, but it is possible to play American Roulette with its double
        zeros too, if that is more of your style.</p>
      <p>“Normal” Roulette is so last season though. In the bitleon Live Casino environment, you’ll find a plethora
        of exotic variants such as Platinum Authentic Roulette, Dup Roulette Saint Vincent, Auto Speed Roulette,
        Immersive Roulette and Lightning Roulette.</p>
      <p>Furthermore, you can also play Roulette at a private table. Some of the games are available in native
        languages so check out Ruletka Live, Svensk Roulette and Deutsches Roulette too.</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Live Baccarat</h2>
    <div>
      <p>If you’re a Baccarat fan, then you’re in for a treat thanks to variations like Live Baccarat (Vivo), Live
        Baccarat (Evolution), Baccarat Squeeze, Baccarat Controlled Squeeze and Salon Privé Baccarat.</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Other Live Games</h2>
    <div>
      <p>If all of that wasn’t enough, there’s also Live Dream Catcher – the colorful money-wheel game – and Live
        Football Studio Top card, which is a card game based on live soccer/football fixtures.</p>
      <p>It’s safe to say that these games let you mix it up with something fresh when the situation calls for it.
      </p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Access to Live Casino</h2>
    <div>
      <p>All you need to do to get in on the Live Casino action (or check out the free play versions on offer) is
        register an account with us. Games are broadcast in real time, so bitleon casino action is locked, loaded,
        and live!</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Languages</h2>
    <div>
      <p>If you were playing at a regular brick-and-mortar casino, you’d expect to be spoken to in your native
        tongue and that’s exactly what bitleon offers. Call it a casino experience that is home away from home if
        you will.</p>
      <p>Experience your favourite Live games in the following languages: Arabic, Bulgarian, Chinese, Dutch,
        English, Finnish, French, Georgian, German, Greek, Hindi, Indonesian, Italian, Japanese, Korean, Mongolian,
        Norwegian, Persian, Portuguese, Russian, Serbian, Spanish, Swedish, Thai, Turkish or Vietnamese.</p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Currencies</h2>
    <div>
      <p>As bitleon is the leading cryptocurrency casino, you’ll be able to bet on Live games using Bitcoin, Ether,
        Litecoin and Bitcoin Cash.</p>
      <p>Fancy something a little more traditional? We accept the Euro, US Dollar, Canadian Dollar, Australian
        Dollar, New Zealand Dollar, Japanese Yen, Renminbi, Polish Złoty, Russian Ruble and Norwegian Krone as well.
      </p>
    </div>
  </div>
  <div :style="{ display: readMoreData }" class="c-site-description__section">
    <h2>Live Games Where You Are!</h2>
    <div>
      <p>Desktop play, mobile play, and everything in-between, thanks to bitleon you’ll be able to play the very
        best live casino games from wherever you choose, and on the device that suits you best.</p>
      <p>If you’re ready for a 5-star live casino experience, one thing is for sure, you’ve come to the right place!
      </p>
    </div>
  </div>
</section>
    </div>
  </main>
  <div>
    <footerBottom />
  </div>
</template>

<script>
import axios from 'axios'; // Import axios for making HTTP requests
import footerBottom from '../parts/footer.vue'; // Import footer component

export default {
  name: 'Table Games',
  data() {
    return {
      readMoreData: 'none',
      readMoreDataButton: 'inline-flex',
      games: [], // Array to store games
      currentPage: 1, // Track the current page
      totalPages: null // Track total pages from pagination
    };
  },
  props: {
    msg: String
  },
  components: {
    footerBottom
  },
  mounted() {
    // Call the API to fetch initial games
    this.fetchGames();
  },
  methods: {
    t(key) {
      return this.$t(key);
    },
    // Function to fetch games from API
    async fetchGames() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_KEY}/api/game/get-game-by-game-type`, {
          params: {
            status: true,
            page: this.currentPage,
            game_type:100,
            limit: 28,
            site_auth_key:process.env.VUE_APP_API_SITE_AUTH_KEY
          }
        });
        if (response.status === 200 && response.data.success) {
          // Update games array with new data
          this.games = [...this.games, ...response.data.data];
          this.totalPages = response.data.pagination.totalPages;
        } else {
          console.error('Failed to fetch games:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    },
    // Function to load more games
    loadMore() {
      if (this.currentPage < this.totalPages) {
        // Increment current page and fetch more games
        this.currentPage++;
        this.fetchGames();
      }
    },
    readMore() {
      this.readMoreData = 'block';
      this.readMoreDataButton = 'none';
    },
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.load-more-container {
  text-align: center;
}

.load-more-button {
  background-color: #1e90ff; /* Adjust color as needed */
  border: none;
  color: white;
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.load-more-button:hover {
  background-color: #007bff; /* Adjust hover color as needed */
}

.load-more-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-top: 2px solid rgba(255, 255, 255, 0.7);
  border-right: 2px solid rgba(255, 255, 255, 0.7);
  animation: loadMoreAnimation 0.8s infinite alternate;
}

@keyframes loadMoreAnimation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(180deg);
  }
}
.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {
  .c-swiper-wrapper {
    padding: 0 15px
  }
}

.is-mobile .c-swiper-wrapper,
.is-tablet .c-swiper-wrapper {
  padding: 0
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2
}

@media(max-width:1024px) {
  .c-swiper-header {
    margin: 0 0 11px
  }
}

.is-mobile .c-swiper-header,
.is-tablet .c-swiper-header {
  padding: 0 45px
}

@media(max-width:1024px) {

  .is-mobile .c-swiper-header,
  .is-tablet .c-swiper-header {
    padding: 0 15px
  }
}

.c-swiper-header__box {
  align-items: center;
  display: flex
}

.c-swiper-container {
  overflow: hidden;
  position: relative
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px)
}

.c-swiper::-webkit-scrollbar {
  display: none;
  height: 0;
  width: 0
}

.c-swiper.scroll-enabled {
  overflow: scroll
}

@media(max-width:1024px) {
  .c-swiper {
    margin-left: -5px;
    width: calc(100% + 10px)
  }
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px
}

.is-mobile .c-swiper,
.is-tablet .c-swiper {
  margin: 0;
  padding: 0 45px;
  width: 100%
}

@media(max-width:1024px) {

  .is-mobile .c-swiper,
  .is-tablet .c-swiper {
    padding: 0 15px
  }
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%
}

.c-swiper-item.is-placeholder {
  width: 6.65%
}

.is-mobile .c-games-swiper-section .c-swiper-item,
.is-tablet .c-games-swiper-section .c-swiper-item {
  justify-content: flex-start;
  min-width: 134px;
  padding: 5px 10px 5px 0;
  width: 134px
}

.is-mobile .c-games-swiper-section .c-swiper-item:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item:last-child {
  min-width: 169px;
  padding: 5px 45px 5px 0;
  width: 169px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 139px;
    padding: 5px 15px 5px 0;
    width: 139px
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins {
  min-width: 305px;
  width: 305px
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
.is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  min-width: 340px;
  width: 340px
}

@media(max-width:1024px) {

  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
    min-width: 310px;
    width: 310px
  }
}

@media(max-width:478px) {
  .is-mobile .c-games-swiper-section .c-swiper-item {
    min-width: 34%;
    width: 34%
  }

  .is-mobile .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 31.375%;
    padding-right: 0;
    width: 31.375%
  }
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins {
  max-width: 305px;
  min-width: auto;
  width: 64.35%
}

.is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
  max-width: 295px;
  min-width: auto;
  padding-right: 0;
  width: 61.45%
}

.c-swiper-side-gradient {
  height: calc(100% - 20px);
  position: absolute;
  top: 10px;
  z-index: 4
}

@media(max-width:1024px) {
  .c-swiper-side-gradient {
    height: calc(100% - 10px);
    top: 5px
  }
}

.c-swiper-side-placeholder {
  background: linear-gradient(90deg, #1b1928, rgba(27, 25, 40, 0));
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .25s ease-in;
  width: .5%
}

.c-swiper-side-placeholder.is-visible {
  opacity: 1
}

.c-swiper-side-navigation {
  background: linear-gradient(90deg, rgba(27, 25, 40, 0) 40%, #1b1928);
  pointer-events: none;
  right: 0;
  transition: all .25s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 5%
}

.c-swiper-side-navigation.should-hide-gradient {
  background: none
}

.c-swiper-side-navigation .c-swiper-navigation {
  align-items: flex-end;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;
  width: 100%
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  -webkit-backdrop-filter: blur(14px);
  backdrop-filter: blur(14px);
  margin-right: 0;
  opacity: 0;
  pointer-events: all;
  transition: opacity .25s ease-in
}

.c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow:first-child {
  margin-top: 10px
}

.c-swiper-container:hover .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
  opacity: 1
}

.is-desktop .c-swiper-side-navigation,
.is-desktop .c-swiper-side-placeholder {
  bottom: 10px;
  height: calc(100% - 60px);
  top: auto
}

@media(max-width:1024px) {

  .is-desktop .c-swiper-side-navigation,
  .is-desktop .c-swiper-side-placeholder {
    bottom: 5px;
    height: calc(100% - 50px)
  }
}

.c-game-badges {
  position: absolute;
  justify-content: space-between;
  top: 15px;
  right: 7%;
  display: flex;
  width: 85%;
}

.c-game-badges-collection {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  z-index: 5;
}

.c-game-badges span {
  flex: 0 0 auto;
  margin-right: 3px;
}

.c-game-badge.is-exclusive {
  background-color: #00bd71;
}

.c-game-badges span:last-child {
  margin-right: 0;
}

.c-game-badges .c-game-badge__text {
  margin: 0;
  /* position: relative;
    top: 15px; */
}

.c-game-badge {
  fill: #fff;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  cursor: default;
  display: flex;
  font-size: 11px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0 4px;
  position: relative;
}

.c-swiper-container .game .c-game-badges .game-badge-provider {
  display: none;
}

.c-swiper-container .c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

@media (hover: hover) {
  .c-game-container:hover .c-game-box__image {
    transform: scale3d(1.05, 1.05, 1);
    z-index: 4;
    position: relative;
  }

  .c-game-container:hover {
    background: #3c3955;
  }
}

.c-swiper-container .game .c-game-box img {
  border-radius: 10px;
}

.c-swiper-item-games {
  display: flex;
}

.c-game-badge.is-bs-original {
  background-color: #e31351;
  /* margin-right: 5px; */
}

.c-game-badge__iconb {
  width: 0;
}

.sprite-icons {
  width: 0;
}

.c-game-badge.is-jackpot {
  background: #854dff;
  color: #fff;
}

.c-game-badge.is-new {
  background-color: #e31351;
}

.c-swiper-wrapper {
  margin: 0 auto 30px;
  max-width: 1420px;
  padding: 0 45px;
  width: 100%;
}

.c-swiper-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 0 8px;
  position: relative;
  z-index: 2;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.games-sections__title {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 15px 0 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: uppercase;
}

.games-sections__title.is-clickable {
  cursor: pointer;
}

.homepage-variation-2 .games-sections__title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: capitalize;
}

.c-swiper-navigation {
  align-items: center;
  display: flex;
}

.c-swiper-header__box {
  align-items: center;
  display: flex;
}

.c-swiper-container {
  overflow: hidden;
  position: relative;
}

.is-desktop .c-swiper-container {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper {
  -ms-overflow-style: none;
  display: flex;
  height: inherit;
  margin-left: -10px;
  overflow: hidden;
  scrollbar-width: none;
  width: calc(100% + 20px);
}

.c-swiper.scroll-enabled {
  overflow: scroll;
}

.is-desktop .c-swiper {
  margin-top: -40px;
  padding-top: 40px;
}

.c-swiper-item {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  height: inherit;
  width: 100%;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__body {
  align-items: center;
  aspect-ratio: 1.85;
  background-color: hsla(0, 0%, 100%, .05);
  display: flex;
  justify-content: center;
}

.is-variation-2 .c-provider-card__body {
  aspect-ratio: 2/1;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.c-providers-swiper__item {
  box-sizing: border-box;
  display: inline-block;
  flex: 0 1 auto;
  line-height: 0;
  padding: 10px;
  position: relative;
  width: 20%;
}

.c-provider-card {
  background: #3c3955;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
}

.is-variation-2 .c-provider-card,
.is-variation-2 .c-provider-card:not(.is-disabled):hover {
  background: transparent;
}

.c-provider-card__image {
  max-height: 80%;
  max-width: 80%;
  transition: transform .2s ease-out;
}

.bs-c-homepage-v2-title {
  text-align: left;
}

.buy-img {
  height: 20px;
}

.games-block__button {
  margin: auto;
}

.c-site-description__section {
  text-align: left;
}

.c-game-badges .game-badge-provider {
  display: none;
}

.c-game-container:hover .c-game-badges .game-badge-provider {
  display: flex;
  z-index: 5;
}

.c-game-container .c-game-box img {
    border-radius: 10px;
}
</style>





