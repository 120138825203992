<template>
  <div>
    <!--deposit Modal -->
    <div
      v-if="modalState.isModalOpen1"
      class="modal fade depsoitModal"
      style="z-index: 1000"
    >
      <div class="modal-content">
        <span
          class="close"
          style="font-size: 45px; margin-right: 10px"
          @click="closeModal1"
          >&times;</span
        >
        <div class="box" style="padding: 15px" v-if="modalState.step1 === 1">
          <p class="large-text">
            {{ t(`ENTER`) }} {{ t(`DEPOSIT`) }} {{ t(`AMOUNT`) }}
          </p>
          <div style="width: 100%">
            <input
              v-model="inputAmount"
              class="input-field"
              placeholder="0.15"
            />
          </div>
          <!-- Tabs for Manual Deposit and Crypto -->
          <div class="tabs">
            <button
              :class="{ activeTab: selectedTab === 'manual' }"
              @click="selectedTab = 'manual'"
            >
              {{ t("Manual Deposit") }}
            </button>
            <button
              :class="{ activeTab: selectedTab === 'crypto' }"
              @click="selectedTab = 'crypto'"
            >
              {{ t("Crypto Deposit") }}
            </button>
          </div>
          <div v-if="selectedTab === 'manual'" class="manual-container">
            <p class="large-text">{{ t(`Manual`) }} {{ t(`Getway`) }}</p>
            <div class="payment-cards">
              <div
                class="card"
                v-for="(gateway, index) in data"
                :key="index"
                :class="{ selected: selectedGateway === gateway }"
                @click="selectGateway(gateway)"
                style="width: 130px"
              >
                <img
                  :src="gateway?.image"
                  alt="Image"
                  style="
                    height: 60px;
                    width: 60px;
                    border-radius: 50%;
                    cursor: pointer;
                  "
                />
                <p style="font-size: 10px; margin-top: 5px; color: #f43f5e">
                  {{ t(`Limit`) }} (<span
                    >{{ gateway?.min_limit }} - {{ gateway?.max_limit }}</span
                  >) {{ gateway?.currency }}
                </p>
              </div>
            </div>
              <button class="depsoitBtn" @click="nextStep">
            {{ t(`Next`) }}
          </button>
          </div>
          <div v-if="selectedTab === 'crypto'" class="crypto-container">
            <p class="large-text">{{ t("Crypto Getway") }}</p>
            <div v-if="cryptoLoader" class="cryptoLoaderBox">
              <div class="spinner"></div>
            </div>
            <div v-else class="crypto-section">
              <div
                class="crypto-images"
                v-for="(gateway, index) in cryptoData"
                :key="index"
                @click="selectCryptoGetway(gateway)"
                :class="[
                  'crypto-images',
                  { cryptoActive: selectedCryptoActive === gateway?.id },
                ]"
              >
              <div class="crypto-box">
                <img
                  :src="gateway?.icon"
                  alt="Crypto Image"
                  class="crypto-image"
                />
                <p style="font-size: 14px; font-weight: bold">
                  {{ gateway?.currency }}
                </p>
              </div>
              <span class="platform">{{ gateway?.platform }}</span>
            </div>
            </div>
            <button class="depsoitBtn" @click="handleCryptoDeposit">
              <div
                                  class="spinner"
                                  style="margin-top: 0px"
                                  v-if="nextLoader"
                                ></div>
                                <span v-else>{{t(`Next`)}}</span>
          </button>
          </div>

        
        </div>
        <div v-if="modalState.step1 === 2">
          <div style="padding: 20px">
            <div class="large-text" style="text-align: left">
              <span>
                {{ t(`Marchants`) }} {{ t(`Bank`) }} {{ t(`Details`) }}</span
              >
            </div>
            <div style="margin-top: 10px; padding: 15px; border-radius: 8px">
              <div
                v-for="(detail, index) in selectedGateway.admin_details"
                :key="index"
                :style="
                  'display: flex;  justify-content: space-between; border-bottom: ' +
                  (index !== selectedGateway.admin_details?.length - 1
                    ? '1px solid #D3D7DB'
                    : 'none')
                "
              >
                <p style="font-weight: bold; white-space: nowrap">
                  {{ detail.fieldName }}
                </p>
                <p>{{ detail.fieldValue }}</p>
              </div>
            </div>
            <div
              style="
                width: 100%;
                color: #3b3955;
                margin-top: 5px;
                margin-bottom: 10px;
              "
            >
              ------------------------------------------------------------------------
            </div>
            <div>
              <div class="large-text" style="text-align: left">
                <span> {{ t(`User`) }} {{ t(`Bank`) }} {{ t(`Details`) }}</span>
              </div>
              <div style="padding: 8px; border-radius: 8px; width: 100%">
                <div
                  v-for="(detail, index) in selectedGateway?.user_details"
                  :key="index"
                  :style="
                    'display: flex;width:100%;gap:10px; justify-content: space-between; align-items:center; border-bottom: ' +
                    (index !== selectedGateway.admin_details.length - 1
                      ? 'none'
                      : 'none')
                  "
                >
                  <p style="font-weight: bold; white-space: nowrap">
                    {{ detail.name }}
                  </p>
                  :
                  <input
                    v-if="detail.type === 'number' || detail.type === 'text'"
                    v-model="userInputs[index]"
                    :type="detail?.type"
                    :placeholder="'Enter ' + detail.name"
                    :required="detail.required"
                    style="
                      width: 90%;
                      outline: none;
                      border: none;
                      padding: 10px;
                    "
                  />

                  <input
                    v-else-if="detail.type === 'file'"
                    type="file"
                    @change="handleFileChange($event, index)"
                    :required="detail.required"
                  />
                </div>
                <div v-if="imgLoader" class="spinner"></div>
              </div>
            </div>

            <button @click="CreateDeposit()" type="submit" class="depsoitBtn">
              <div class="spinner" v-if="depositLoader"></div>

              <span v-else>{{ t(`Submit`) }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { inject } from "vue";
export default {
  components: {},
  name: "Header",
  setup() {
    const modalState = inject("modalState");
    const closeModal1 = inject("closeModal1");

    return {
      modalState,
      closeModal1,
    };
  },
  data() {
    return {
      // Initialize with the sidebar closed or at a certain width
      SITEAUTHKEY: "",
      userData: {},
      data: [],
      limit: 10,
      isModalOpen1: false,
      amount: "",
      depositLoader: false,
      imgLoader: false,
      selectedGateway: null,
      userInputs: [],
      selectedFiles: [],
      step: 1,
      inputValue: "",
      selectedTab: "manual",
      cryptoData: [], // Crypto payment data from API
      prevRates: {}, // To store previous rates for comparison
      blinkRates: {}, // To manage blinking effect for rate change
      loading: true, // Loading state for API call
      error: null,
      cryptoLoader: false,
      selectedCrypto: "",
      selectedCryptoActive: "",
      nextLoader:false
    };
  },

  methods: {
    handleInput() {
      // Set referCode to the current input value when user types
      this.referCode = this.inputValue;
    },
  

    selectCryptoGetway(gateway) {
      this.selectedCryptoActive = gateway.id;
      this.selectedGateway = gateway;
    },

    selectGateway(gateway) {
      // console.log(gateway,"cheickn getway")
      this.selectedGateway = gateway;
    },

    nextStep() {
      let data = JSON.parse(JSON.stringify(this.selectedGateway));

      if (!this.inputAmount) {
        toast("Please enter a valid amount to continue.");
        // console.log(data,"checking")

        return;
      }
      if (!this.selectedGateway) {
        toast("Please select a payment gateway to proceed.");
        // console.log(data,"checking")
        return;
      }

      if (this.inputAmount && data) {
        if (
          this.inputAmount <= data?.max_limit &&
          this.inputAmount >= data?.min_limit
        ) {
          this.modalState.step1 = 2;
        } else {
          // console.log(data,"checking else")

          toast(`Please enter an amount within the range of ${data.min_limit} and ${data.max_limit}.`);

        }
      }
    },
   
    async handleCryptoDeposit(){
     
        console.log(this.selectedGateway,"selectedCryptoActive")
        if(!this.inputAmount){
          toast("Please enter a valid amount to continue.");
          return;
        }
        if(!this.selectedGateway){
          toast("Please select a payment gateway to proceed.");
          return;
        }
        this.nextLoader=true

        try {
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");

        if (!userToken || !usernameToken) {
          return;
        }

        const payload = {
            amount:Number(this.inputAmount),
          site_auth_key: this.SITEAUTHKEY,
          currencies:this.selectedGateway?.id,

        
        };

        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/auto-payment/create-passim-pay-order`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: userToken,
              usernametoken: usernameToken,
            },
            body: JSON.stringify(payload),
          }
        );

        // Check if the request was successful
        if (!response.ok) {
        console.log(response?.message,"respose")
          
          throw new Error("Failed to fetch payment methods");
        }

        // Parse the response JSON
        const data = await response.json();

        console.log(data,"respose")
        if (data?.data?.url) {
        window.open(data?.data?.url, '_blank');
      }
      
      } catch (error) {
        toast(error?.message);

        console.error("Error fetching payment methods:", error);
      } finally {
        this.nextLoader=false

      }
    },

    t(key) {
      return this.$t(key);
    },
    async fetchUserData() {
      try {
        // Retrieve tokens from localStorage
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");

        // Check if tokens exist
        if (!userToken || !usernameToken) {
          console.error("User tokens not found in localStorage");
          return;
        }

        // Make GET request to the API endpoint
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/user/get-single-user?site_auth_key=${this.SITEAUTHKEY}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token: userToken,
              usernametoken: usernameToken,
            },
          }
        );

        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        // Parse the response JSON
        const userData = await response.json();
        // Save the retrieved user data
        this.userData = userData;
        // console.log("User data during in header:", userData);

        localStorage.setItem("username", userData.data.username);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },

    async PaymentMethods() {
      // console.log("fucntion call");
      try {
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");

        if (!userToken || !usernameToken) {
          return;
        }

        const payload = {
          parent_admin_role_type: localStorage.getItem(
            "parent_admin_role_type"
          ),
          parent_admin_username: localStorage.getItem("parent_admin_username"),
          site_auth_key: this.SITEAUTHKEY,
        };

        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/payment/get-payment-method?type=deposit`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: userToken,
              usernametoken: usernameToken,
            },
            body: JSON.stringify(payload),
          }
        );

        // Check if the request was successful
        if (!response.ok) {
          throw new Error("Failed to fetch payment methods");
        }

        // Parse the response JSON
        const paymentMethods = await response.json();
        // console.log("Payment methods:", paymentMethods);
        // console.log("Payment methods:", paymentMethods.data);
        this.data = paymentMethods.data;

        // Process the payment methods as needed
        // For example, update UI, display options to the user, etc.
      } catch (error) {
        console.error("Error fetching payment methods:", error);
      } finally {
      }
    },
    async fetchCryptoPaymentMethods() {
      // Fetch tokens from localStorage
      this.cryptoLoader = true;

      const userToken = localStorage.getItem("userToken");
      const usernameToken = localStorage.getItem("usernameToken");

      // If tokens are missing, exit the function
      if (!userToken || !usernameToken) {
        console.error("Missing tokens");
        return;
      }

      try {
        // Make the API request with the tokens in headers
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/auto-payment/get-passimo-pay-currencies`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              token: userToken, // Token from localStorage
              usernametoken: usernameToken, // Username token from localStorage
            },
          }
        );

        // If the response is not OK, throw an error
        if (!response.ok) {
          throw new Error("Failed to fetch crypto payment methods");
        }

        // Parse the JSON response
        const data = await response.json();
        // Update rates and blinking logic
        console.log();
        this.updateCryptoRates(data?.data);
        console.log(data, "data cgheck");
        this.cryptoData = data?.data;
      } catch (error) {
        // Handle error and set error state
        console.error("Error fetching crypto payment methods:", error);
        this.error = "Failed to fetch crypto payment data";
      } finally {
        this.cryptoLoader = false;
      }
    },

    updateCryptoRates(data) {
      const newBlinkRates = {};
      const updatedPrevRates = {};

      data.forEach((item) => {
        const previousRate = this.prevRates[item.id];
        const currentRate = item.rate_usd;

        if (previousRate !== undefined && previousRate !== currentRate) {
          newBlinkRates[item.id] = true;

          setTimeout(() => {
            this.blinkRates = {
              ...this.blinkRates,
              [item.id]: false,
            };
          }, 2000);
        }

        updatedPrevRates[item.id] = currentRate;
      });

      this.prevRates = updatedPrevRates;
      this.blinkRates = newBlinkRates;
    },
    viewDetails(promotionId) {
      // Use Vue Router to navigate to the details page and pass the promotionId
      this.$router.push({
        name: "PromotionDetails",
        params: { promotionId: promotionId },
      });
    },

    async handleFileChange(event, index) {
      // console.log("func");
      const file = event.target.files[0];
      this.selectedFiles[index] = file;
      // console.log(this.selectedFiles);
      this.userInputs[index] = file;

      const formData = new FormData();
      formData.append("post_img", file);

      try {
        this.imgLoader = true; // Show loader when starting image upload

        // console.log(this.imgLoader);
        const response = await this.sendPostRequest(
          `${process.env.VUE_APP_API_KEY}/api/payment/image-url?site_auth_key=${this.SITEAUTHKEY}`,
          formData
        );

        if (response.url) {
          this.imgLoader = false; // Hide loader when image upload is successful
          // console.log(response.url, "response url");
          this.Image_url = response.url;
          // console.log(this.imgLoader);
        }

        return response.url;
      } catch (error) {
        this.imgLoader = false; // Hide loader when image upload fails
        // console.log(this.imgLoader);
        console.error("Error uploading image:", error.message);
      }
    },

    async sendPostRequest(url, formData) {
      try {
        const response = await fetch(url, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json(); // Assuming your API returns JSON response
        return responseData;
      } catch (error) {
        throw new Error(error.message || "Error sending POST request");
      }
    },

    async nextDeposite1() {
      // console.log("apicall");
      let data = JSON.parse(JSON.stringify(this.selectedGateway));
      // console.log(data,"creads")
      try {
        // Retrieve user tokens and ID
        const userToken = localStorage.getItem("userToken");
        const usernameToken = localStorage.getItem("usernameToken");

        // Extract necessary user data from userData
        const userId = this.userData.data.user_id;
        const username = this.userData.data.username;

        if (!userToken || !usernameToken) {
          // console.error("User tokens not found in localStorage");
          return;
        }

        // Create an empty array to store user details
        const userDetails = [];

        // Loop through userInputs array to get user input values
        this.userInputs.forEach((inputValue, index) => {
          // Get the corresponding user detail from promotions array
          const userDetail = data.user_details[index];
          // Create an object with name, value, and required properties
          let detailValue;
          if (userDetail.type === "file") {
            // // For file inputs, use the selected file from selectedFiles array
            // console.log(this.selectedFiles[index].File);
            // console.log(this.selectedFiles[index].name);
            detailValue = this.Image_url;
            // Construct the file detail object to include in userDetails
            const fileDetailObject = {
              name: userDetail.name,
              value: detailValue,
              required: userDetail.required === "true",
            };
          } else {
            // Handle other input types here if needed
            detailValue = inputValue; // Default value for unsupported types
          }
          const detailObject = {
            name: userDetail.name,
            value: detailValue,
            required: userDetail.required === "true",
          };
          // Push the detailObject to userDetails array
          userDetails.push(detailObject);
        });

        let countRequred = data?.user_details?.filter(
          (detail) => detail.required
        ).length;
        if (countRequred !== userDetails.length) {
          // console.log(countRequred,userDetails.length,"cehcking requird count")
          return toast("fill all user bank required details");
        }
        const ids = localStorage.getItem("User_name");
        const payload = {
          method: data.gateway,
          method_url: data.image,
          method_id: data._id,
          username: username,
          user_id: userId,
          user_type: "user",
          deposit_amount: Number(this.inputAmount),
          bonus: data.bonus,
          deposit_slip: this.Image_url, // Assuming only one file is selected
          payable: Number(this.inputAmount),
          after_deposit:
            Number(this.userData?.data?.amount) + Number(this.inputAmount),
          wallet_amount: this.userData?.data?.amount,
          admin_response: "",
          user_details: userDetails, // Include user details array
          admin_details: data.admin_details,
          utr_no: "486",
          type: data.type,
          currency: this.userData?.data?.currency,
          site_auth_key: this.SITEAUTHKEY,
        };
        // console.log(payload, "payload12");
        // Make the POST request to the API endpoint
        this.depositLoader = true;
        const response = await fetch(
          `${process.env.VUE_APP_API_KEY}/api/transaction/create-deposit-request/${ids}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: userToken,
              usernametoken: usernameToken,
            },
            body: JSON.stringify(payload),
          }
        );
        const responseData = await response.json();

        // Check if the request was successful
        if (!response.ok) {
          throw new Error(responseData?.message);
        }
        this.depositLoader = false;

        // Parse the response JSON

        toast("Deposit request created successfully");
        this.closeModal1();
      } catch (error) {
        // console.log("error 123", error.message);
        console.error("Error creating deposit request:", error);
        toast(error?.message || error?.data?.message);
        this.depositLoader = false;
      }
    },

    CreateDeposit() {
      // console.log("ram");/
      this.nextDeposite1();
    },
  },

  mounted() {
    const siteAuthKey = process.env.VUE_APP_API_SITE_AUTH_KEY;
    // console.log(siteAuthKey, "checking site Auth key");
    this.SITEAUTHKEY = siteAuthKey;
    this.PaymentMethods();
    this.fetchUserData();
    this.fetchCryptoPaymentMethods();
  },
};
</script>

<style scoped>
.input-field {
  outline: none;
  border: none;
  border-bottom: 1px solid gray;
  font-size: 20px;
  width: 100%;
  padding: 8px;
}
.depsoitModal {
  z-index: 100;
  padding: 15px;
}


.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(61, 58, 58, 0.5);
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  text-align: center;
  width: 34%;
  background-color: white;
  border-radius: 16px;
  padding: 10px;
  height: 460px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}
.box {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: start;
  align-items: start;
  margin: 0px;
}
.large-text {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 10px;
  margin: 0px;
}

.payment-cards {
  display: flex;
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  overflow: scroll;
  /* border:2px solid red; */
  height: 140px;
}
.card {
  padding: 10px;
  border: 1px solid #ccc;

  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100px;
  height:80%;
  /* border:2px solid red; */
  transition: background-color 0.3s;
}
.card.selected {
  background-color: #3861fb;
  color: white;
}
.card:hover {
  /* background-color: #f0f0f0; */
}
.depsoitBtn {
  padding: 10px 20px;
  background-color: #01bd71;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
.depsoitBtn:disabled {
  background-color: #01bd71;
  cursor: not-allowed;
}

.tabs {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  width: 100%;
  gap: 20px;
}

.tabs button {
  background-color: #3b3955;
  border: none;
  padding: 10px 20px;
  /* margin: 0 10px; */
  cursor: pointer;
  width: 100%;
  font-weight: bold;
  transition: background-color 0.3s;
}

.tabs button.activeTab {
  background-color: #01bd71;
  color: white;
}
.crypto-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: start;
  align-items: start;
  padding: 10px;
  width: 100%;
}
.manual-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: start;
  align-items: start;
  padding: 10px;
  width: 100%;
}
.cryptoLoaderBox {
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border:2px solid red */
}
.crypto-section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  overflow: scroll;
  /* border:2px solid red; */
  height: 140px;
}

.crypto-images {
  display: flex;
  gap: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  /* padding: 10px; */
  border-radius: 6px;
  padding: 0px;
  height: 50px;
  border: 1px solid gray;
  background-color: #dfe4e8;
  padding-right: 10px;
  cursor: pointer;
}
.platform{
  /* border:2px solid red; */
  margin-top: -12px;
  font-size: 9px;
  font-weight: 800;
  height: 20px;
  margin-left: 8px;
}
.crypto-box{
  display:flex;
  justify-content: space-between;
  align-items: center;
  gap:6px
}

.crypto-image {
  height: 22px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s;
  color: #585d6d;
}

.crypto-images.cryptoActive {
  background-color: #3861fb; /* Background color when active */
  color: white;
}

.crypto-image:hover {
  transform: scale(1.1);
}

@media (max-width: 1400px) {
  .modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 5px;
    text-align: center;
    width: 40%;
    background-color: white;
    border-radius: 16px;
    padding: 10px;
    position: relative;
  }
}

@media (max-width: 854px) {
  .crypto-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 8px;
    flex-wrap: wrap;
    margin-bottom: 20px;
    overflow: scroll;
    /* border:2px solid red; */
    height: 340px;
  }
  .modal-content {
    background-color: white;
    padding: 40px;
    border-radius: 0px;
    top: 0px;
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 10px;
    position: fixed;
  }

  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background-color: black;
  }
}
</style>
