import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import Slots from '../components/Slots.vue';
import TableGames from '../components/TableGames.vue';
// import BitstarzOriginalsGames from '../components/BitstarzOriginalsGames.vue';
import BetAlong from '../components/BetAlong.vue';
import GameCategory from '../components/AllGameCategory.vue';
import LiveCasino from '../components/LiveCasino.vue';
import Bitcoin from '../components/Bitcoin.vue';
import TopGames from '../components/TopGames.vue';
import ExclusiveGames from '../components/ExclusiveGames.vue';
import TrendingGames from '../components/TrendingGames.vue';
import GameShows from '../components/GameShows.vue';
import NewGames from '../components/NewGames.vue';
import HotGames from '../components/HotGames_24H.vue';
import ColdGames from '../components/ColdGames_24H.vue';
import Megaways from '../components/Megaways.vue';
import BuyBonusGames from '../components/BuyBonusGames.vue';
import ClassicSlots from '../components/ClassicSlots.vue';
import BookOfGames from '../components/BookOfGames.vue';
import ProvablyFairGames from '../components/ProvablyFairGames.vue';
import VIP from '../components/VIP.vue';
import faq from '../components/faq.vue';
import AboutBitcoin from '../components/AboutBitcoin.vue';
import BTCToEUR from '../components/BTCToEUR.vue';
import ProvabilityExplanation from '../components/ProvabilityExplanation.vue';
import PlaySafe from '../components/PlaySafe.vue';
import Complaints from '../components/Complaints.vue';
import HowToBuyCrypto from '../components/HowToBuyCrypto.vue';
import AboutUs from '../components/AboutUs.vue';
import Terms from '../components/Terms.vue';
import BonusesTerms from '../components/BonusesTerms.vue';
import Payments from '../components/Payments.vue';
import PrivacyPolicy from '../components/PrivacyPolicy.vue';
import Promotions from '../components/Promotions.vue'
import IframeView from '@/components/iframeview/IframeView.vue';
import AllGames from  '../components/AllGames.vue'
import GameProviderWise from '@/components/GameProviderWise.vue';
import CloneProviderGame from "@/components/clonegames/CloneProviderGame.vue"
import CloneIframe from "../components/clonegames/CloneIframe.vue" 
import CloneAllGameByCategory from "@/components/clonegames/CloneProviderGameByCategory.vue"
// Import other components you want to route to
import GameStudio from '../components/GameStudio.vue'
import ReferAndEarn from '@/components/ReferAndEarn.vue';
import GameProvider from '@/components/GameProvider.vue';
// import Payments from '../components/Payments.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: {
      title: 'Welcome to Bitleon Casino',
      description: 'Explore a wide range of casino games, promotions, and more at Bitleon Casino.'
    }
  },
  {
    path: '/slots',
    name: 'Slots',
    component: Slots,
    meta: {
      title: 'Slots - Bitleon Casino',
      description: 'Play the best slot games at Bitleon Casino. Find your favorite slots and start spinning.'
    }
  },
  {
    path: '/slots/casino/:gpId/:gameId',
    name: 'Slots Casino',
    component: IframeView,
    meta: {
      title: 'Slot Casino Game - Bitleon Casino',
      description: 'Enjoy our slot casino games. Spin the reels and win big at Bitleon Casino.'
    }
  },
  {
    path: '/game/exclusive-casino-game/:provider_id/:game_id/:api_provider_name',
    name: 'Clone Casino',
    component: CloneIframe,
    meta: {
      title: 'Clone Casino Game - Bitleon Casino',
      description: 'Enjoy our Clone casino games. Spin the reels and win big at Bitleon Casino.'
    }
  },
  {
    path: '/table-games',
    name: 'TableGames',
    component: TableGames,
    meta: {
      title: 'Table Games - Bitleon Casino',
      description: 'Experience classic table games like Blackjack, Poker, and more at Bitleon Casino.'
    }
  },
  {
    path: '/refer-earn',
    name: 'ReferEarn',
    component: ReferAndEarn,
    meta: {
      title: 'Refer Earn - Bitleon Casino',
      description: 'Join us in classic table games like Blackjack and Poker at Bitleon Casino. Refer friends to earn rewards!'
    }
  },
  {
    path: '/game-provider/:gpName/:gpId',
    name: 'GameProviderWise',
    component: GameProviderWise,
    meta: {
      title: 'Games by Provider - Bitleon Casino',
      description: 'Browse games by your favorite providers at Bitleon Casino.'
    }
  },
  {
    path: '/game-by-provider/:gpName/:gpId/:apiProviderName',
    name: 'CloneProviderGame',
    component: CloneProviderGame,
    meta: {
      title: 'Games by Provider - Bitleon Casino',
      description: 'Browse games by your favorite providers at Bitleon Casino.'
    }
  },
  
  {
    path: '/table-games/:gpId/:gameId',
    name: 'TableGames Casino',
    component: IframeView,
    meta: {
      title: 'Table Game Casino - Bitleon Casino',
      description: 'Play our table casino games and experience the thrill at Bitleon Casino.'
    }
  },
  {
    path: '/promotions',
    name: 'Promotions',
    component: Promotions,
    meta: {
      title: 'Current Promotions - Bitleon Casino',
      description: 'Check out our latest promotions and bonuses available at Bitleon Casino.'
    }
  },
  {
    path: '/payments',
    name: 'Payments',
    component: Payments,
    meta: {
      title: 'Current Payments - Bitleon Casino',
      description: 'Check out our latest promotions and bonuses available at Bitleon Casino.'
    }
  },
  {
    path: '/game-category/:category/:name',
    name: 'GameCategory',
    component: GameCategory,
    meta: {
      title: 'Game Category - Bitleon Casino',
      description: 'Discover games in various categories at Bitleon Casino.'
    }
  },

    {
    path: '/ex-game-category/:category/:name',
    name: 'ExGameCategory',
    component: CloneAllGameByCategory,
    meta: {
      title: 'Game Category - Bitleon Casino',
      description: 'Discover games in various categories at Bitleon Casino.'
    }
  },
  {
    path: '/game/casino/:gpId/:gameId',
    name: 'Jackpot',
    component: IframeView,
    meta: {
      title: 'Jackpot Game - Bitleon Casino',
      description: 'Play jackpot games and aim for big wins at Bitleon Casino.'
    }
  },
  {
    path: '/live-casino',
    name: 'LiveCasino',
    component: LiveCasino,
    meta: {
      title: 'Live Casino - Bitleon Casino',
      description: 'Experience the excitement of live casino games at Bitleon Casino.'
    }
  },
  {
    path: '/live-casino/:gpId/:gameId',
    name: 'LiveCasinos',
    component: IframeView,
    meta: {
      title: 'Live Casino Game - Bitleon Casino',
      description: 'Join live casino games and play in real-time at Bitleon Casino.'
    }
  },
  {
    path: '/casino/top-rated-games/:name',
    name: 'TopGames',
    component: TopGames,
    meta: {
      title: 'Top Rated Games - Bitleon Casino',
      description: 'Check out the top-rated games at Bitleon Casino.'
    }
  },
  {
    path: '/casino/all-games/all',
    name: 'AllGames',
    component: AllGames,
    meta: {
      title: 'All Games - Bitleon Casino',
      description: 'Browse through all the games available at Bitleon Casino.'
    }
  },
  {
    path: '/top-games/:gpId/:gameId',
    name: 'TopGame',
    component: IframeView,
    meta: {
      title: 'Top Game - Bitleon Casino',
      description: 'Play the top games and enjoy great entertainment at Bitleon Casino.'
    }
  },
  {
    path: '/exclusive-games',
    name: 'ExclusiveGames',
    component: ExclusiveGames,
    meta: {
      title: 'Exclusive Games - Bitleon Casino',
      description: 'Enjoy exclusive games available only at Bitleon Casino.'
    }
  },
  {
    path: '/trending-games',
    name: 'TrendingGames',
    component: TrendingGames,
    meta: {
      title: 'Trending Games - Bitleon Casino',
      description: 'Discover the games trending at Bitleon Casino.'
    }
  },
  {
    path: '/game-shows',
    name: 'GameShows',
    component: GameShows,
    meta: {
      title: 'Game Shows - Bitleon Casino',
      description: 'Join the fun with exciting game shows at Bitleon Casino.'
    }
  },
  {
    path: '/game-studios',
    name: 'GameStudio',
    component: GameStudio,
    meta: {
      title: 'Game Shows - Bitleon Casino',
      description: 'Join the fun with exciting game shows at Bitleon Casino.'
    }
  },
  {
    path: '/game-provider/:apiProviderName',
    name: 'GamesProvider',
    component: GameProvider,
    meta: {
      title: 'Game Provider - Bitleon Casino',
      description: 'Join the fun with exciting game provider at Bitleon Casino.'
    }
  },
  {
    path: '/new-games',
    name: 'NewGames',
    component: NewGames,
    meta: {
      title: 'New Games - Bitleon Casino',
      description: 'Be the first to play the newest games at Bitleon Casino.'
    }
  },
  {
    path: '/hot-games',
    name: 'HotGames',
    component: HotGames,
    meta: {
      title: 'Hot Games - Bitleon Casino',
      description: 'Play the hottest games that are currently popular at Bitleon Casino.'
    }
  },
  {
    path: '/cold-games',
    name: 'ColdGames',
    component: ColdGames,
    meta: {
      title: 'Cold Games - Bitleon Casino',
      description: 'Check out games that are less popular at Bitleon Casino.'
    }
  },
  {
    path: '/megaways-games',
    name: 'Megaways',
    component: Megaways,
    meta: {
      title: 'Megaways Games - Bitleon Casino',
      description: 'Play games featuring the popular Megaways mechanics at Bitleon Casino.'
    }
  },
  {
    path: '/classic-slots-games',
    name: 'ClassicSlots',
    component: ClassicSlots,
    meta: {
      title: 'Classic Slots - Bitleon Casino',
      description: 'Enjoy the nostalgia of classic slot games at Bitleon Casino.'
    }
  },
  {
    path: '/vip-starz-club',
    name: 'VIP',
    component: VIP,
    meta: {
      title: 'VIP Starz Club - Bitleon Casino',
      description: 'Join the VIP Starz Club for exclusive benefits at Bitleon Casino.'
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: faq,
    meta: {
      title: 'Frequently Asked Questions - Bitleon Casino',
      description: 'Find answers to your questions about Bitleon Casino and our services.'
    }
  },
  {
    path: '/about-bitcoin',
    name: 'AboutBitcoin',
    component: AboutBitcoin,
    meta: {
      title: 'About Bitcoin - Bitleon Casino',
      description: 'Learn about Bitcoin and its uses in online gaming at Bitleon Casino.'
    }
  },
  {
    path: '/provability-explained',
    name: 'ProvabilityExplanation',
    component: ProvabilityExplanation,
    meta: {
      title: 'Provability Explained - Bitleon Casino',
      description: 'Understand the concept of provability in online gambling at Bitleon Casino.'
    }
  },
  {
    path: '/complaints',
    name: 'Complaints',
    component: Complaints,
    meta: {
      title: 'Customer Complaints - Bitleon Casino',
      description: 'Learn how to address customer complaints at Bitleon Casino.'
    }
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs,
    meta: {
      title: 'About Us - Bitleon Casino',
      description: 'Discover more about Bitleon Casino, our team, and our mission.'
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: {
      title: 'Terms and Conditions - Bitleon Casino',
      description: 'Review the terms and conditions for using Bitleon Casino.'
    }
  },
  {
    path: '/bonus-terms-and-conditions',
    name: 'BonusesTerms',
    component: BonusesTerms,
    meta: {
      title: 'Bonuses Terms and Conditions - Bitleon Casino',
      description: 'Understand the terms and conditions of our bonuses at Bitleon Casino.'
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy - Bitleon Casino',
      description: 'Read our privacy policy to understand how we protect your data at Bitleon Casino.'
    }
  }
  // Add other route objects here
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;