
<template>
    <main >
      <div class="c-games-category" ><!---->
        <div  >
          <section  data-cy="cy-games-sections-wrapper" class="games-sections-wrapper">
            <div   data-cy="cy-games-sections" class="games-sections">
              <div  data-cy="cy-games-sections__heading" class="">
                <div  data-cy="cy-games-sections__heading-box" class="games-sections__heading-box">
                  <button class="c-button games-sections__heading-back has-icon color-light-purple size-m"
                    data-cy="cy-games-sections__heading-back" @click="goBack">
                    <font-awesome-icon :icon="['fas', 'arrow-left']" size="lg" />
                  </button> <!---->
                  <h1  data-cy="cy-games-sections__title" class="games-sections__title">
                  All GAMES
                  </h1> <!---->
                  
                </div>
  
                
              </div>
              <div  style="margin-top: 20px;">
                <div data-cy="cy-games-block" class="game-box " >
                  <div v-for="game in games" :key="game._id" class="" style="">
                <a :href="`/game/casino/${game.gameProviderId}/${game.gameID}?mode=player`" class="c-game-container">
                  <!-- /game/casino/${gpid}/${gameid}?mode=${mode} -->
                  <!-- Game badges -->
                  <div class="c-game-badges">
                    <div class="c-game-badges-collection"></div>
                    <span class="game-badge-separator"></span>
                    <!-- Use first character of provider's name to construct image source -->
                    <span class="game-badge-provider is-bagde-hover">
                      <span class="game-badge-provider__overlay"></span>
                      <img style="width: 10px; height:10px ;" src="https://companieslogo.com/img/orig/EVO.ST-913bcbb0.png?t=1684219168" :alt="game.provider" class="game-badge-provider__icon">
                    </span>
                  </div>
                  <!-- Game box with image -->
                  <div class="c-game-box">
                    <img :src="game.gameInfos[0].gameIconUrl" :alt="game.gameInfos[0].gameName" class="c-game-box__image" style="height:230px;width:100%"> 
                  </div>
                </a>
              </div>
              <template v-if="loading">
            <!-- Skeleton loading state -->
            <div class="skeleton-box">
              <div
                v-for="n in 20"
                :key="n"
                style=" background-color: #2b2740"
                class="skeleton"
              ></div>
            </div>
          </template>
            
                </div> <!---->
                <div data-cy="cy-games-block__footer" class="games-block__footer"><!----></div>
              </div>
            </div>
            <div class="load-more-container">
        <button style="margin-top: 10px" @click="loadMore" v-if="currentPage < totalPages" class="load-more-button">
          LOAD MORE
        </button>
      </div>
          </section>
        </div>
        <section class="c-site-description">
          <div class="c-site-description__section">
            <h2>
              All Games
            </h2>
            <p><span>Come on, let’s be honest. When it comes to playing at an online casino, the tables have to take a
                backseat. In these money-infested waters, the slot machine is the big fish and there are thousands of them
                out there for you to enjoy.</span> 
                
              </p>
            <div>
              <p>Lucky for you, bitleon has a pond bigger than most, where you can take your pick from the best slots on
                the market. Go fish!
                <a :style="{ display: readMoreDataButton }" class="readmore" @click="readMore"> Read more </a>
              </p>
            </div>
          </div>
          <div class="c-site-description__section" :style="{ display: readMoreData }">
            <h2>More Popular Than Ever</h2>
            <div>
              <p>Ever since the slot machine made its way onto the scene all the way back in the late 1890s, the attraction
                for this magical machine has gotten bigger and bigger as time has passed. Today, playing online slots is
                more popular than ever and has become a part of everyday life for millions of people across the globe.</p>
              <p>So why do so many of them come and play at bitleon? Because we listen to our players and take great pride
                in offering the best of the best in terms of online slots.</p>
            </div>
          </div>
          <div class="c-site-description__section" :style="{ display: readMoreData }">
            <h2>Top Slots Standing By</h2>
            <div>
              <p>When it comes to slots, there are a certain group of elite providers that keep coming out with fantastic
                slots and we make sure to team up with them and keep our enormous slot library up to date with the latest
                releases on the market.</p>
              <p>Big-time providers such as <a href="/games/pragmatic">Pragmatic Play</a>, <a href="/games/nolimit">Nolimit
                  City</a>, <a href="/games/booming">Booming Games</a>, <a href="/games/bsg">Betsoft Gaming</a>, <a
                  href="/games/netent">NetEnt</a>, <a href="/games/playngo">Play’n GO</a> and <a
                  href="/games/spinomenal">Spinomenal</a> all have slots standing by to show you a good time and secure you
                those big wins.</p>
              <p>Are you looking for the classic fruit machine, a more interactive game or perhaps the best graphics and
                latest 3D technology? Whatever your preference in a slot is, you are sure to find it right here at bitleon!
              </p>
            </div>
          </div>
          <div class="c-site-description__section"  :style="{ display: readMoreData }">
            <h2>Spin Your Way to Riches</h2>
            <div>
              <p> It’s pretty much impossible to pick just one game that we feel is the best because all players tend to
                find different games entertaining. At bitleon, we never forget that playing slots is all about
                entertainment because after all, that’s why we are here.</p>
              <p>Yes, landing that monster win is something we are all looking for, but the biggest piece of the entire
                online casino cake is after all entertainment. There are some games that seem to stand the test of time
                though. Slots such as <a href="/slots/platinum-lightning">Platinum Lightning</a>, <a
                  href="/slots/wolf-gold">Wolf Gold</a>, <a href="/slots/book-of-dead">Book of Dead</a>, <a
                  href="/slots/elvis-frog-in-vegas">Elvis Frog in Vegas</a> and our very own <a
                  href="/slots/bitleon-billion">bitleon Billion</a> are waiting to send the big bucks in your direction.
              </p>
              <p>If you are a fan of online slots, just like the rest of us, then you can rest assured that you have found
                your way to the ultimate slot capital of the world, bitleon!</p>
            </div>
          </div>
        </section>
  
      </div>
    </main>
    <div>
      <footerBottom />
    </div>
  </template>
  
  <script>
  import axios from 'axios'; // Import axios for making HTTP requests
  import footerBottom from '../parts/footer.vue'; // Import footer component
  import { toast } from "vue3-toastify";
  import "vue3-toastify/dist/index.css";
  export default {
    name: 'AllGames',
    data() {
      return {
        readMoreData: 'none',
        readMoreDataButton: 'inline-flex',
        games: [], // Array to store games
        currentPage: 1, // Track the current page
        totalPages: null ,// Track total pages from pagination
        loading:false
      };
    },
    props: {
      msg: String
    },
    components: {
      footerBottom
    },
    mounted() {
      // Call the API to fetch initial games
      this.fetchGames();
    },
    methods: {
      // Function to fetch games from API
      t(key) {
        return this.$t(key);
      },
      async fetchGames() {
        this.loading=true
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_KEY}/api/game/get-all-game?`, {
            params: {
              status: true,
              page: this.currentPage,
              game_type:201,
              limit: 28,
              site_auth_key:process.env.VUE_APP_API_SITE_AUTH_KEY
            }
          });
          if (response.status === 200 && response.data.success) {
            // Update games array with new data
        this.loading=false

            this.games = [...this.games, ...response.data.data];
            this.totalPages = response.data.pagination.totalPages;
          } else {
        this.loading=false

            console.error('Failed to fetch games:', response.data.message);
          }
        } catch (error) {
        this.loading=false

          console.error('Error fetching games:', error);
        }
      },
      // Function to load more games
      loadMore() {
        if (this.currentPage < this.totalPages) {
          // Increment current page and fetch more games
          this.currentPage++;
          this.fetchGames();
        }
      },
      readMore() {
        this.readMoreData = 'block';
        this.readMoreDataButton = 'none';
      },
      goBack() {
        this.$router.back();
      }
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>


.skeleton-box {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.skeleton {
  width: 180px;
  height: 230px;
  border-radius: 8px;
}
@media screen and (max-width: 1200px) {

.skeleton-box {
display: grid;
width: 100%;
grid-template-columns: repeat(7, 1fr);
gap: 10px;
}

.skeleton {
width: 130px;
height: 160px;
border-radius: 8px;
}
}


@media screen and (max-width:700px) {

.skeleton-box {
display: grid;
width: 100%;
grid-template-columns: repeat(4, 1fr);
gap: 10px;
}

.skeleton {
width: 120px;
height: 130px;
border-radius: 8px;
}
}

@media screen and (max-width:448px) {

.skeleton-box {
display: grid;
width: 100%;
grid-template-columns: repeat(3, 1fr);
gap: 6px;
}

.skeleton {
width: 135px;
height: 140px;
border-radius: 8px;
}
}

  .c-swiper-wrapper {
    margin: 0 auto 30px;
    max-width: 1420px;
    padding: 0 45px;
    width: 100%
  }
  
  @media(max-width:1024px) {
    .c-swiper-wrapper {
      padding: 0 15px
    }
  }
  
  .is-mobile .c-swiper-wrapper,
  .is-tablet .c-swiper-wrapper {
    padding: 0
  }
  
  .c-swiper-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px;
    position: relative;
    z-index: 2
  }
  
  @media(max-width:1024px) {
    .c-swiper-header {
      margin: 0 0 11px
    }
  }
  
  .is-mobile .c-swiper-header,
  .is-tablet .c-swiper-header {
    padding: 0 45px
  }
  
  @media(max-width:1024px) {
  
    .is-mobile .c-swiper-header,
    .is-tablet .c-swiper-header {
      padding: 0 15px
    }
  }
  
  .c-swiper-header__box {
    align-items: center;
    display: flex
  }
  
  .c-swiper-container {
    overflow: hidden;
    position: relative
  }
  
  .is-desktop .c-swiper-container {
    margin-top: -40px;
    padding-top: 40px
  }
  
  .c-swiper {
    -ms-overflow-style: none;
    display: flex;
    height: inherit;
    margin-left: -10px;
    overflow: hidden;
    scrollbar-width: none;
    width: calc(100% + 20px)
  }
  
  .c-swiper::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0
  }
  
  .c-swiper.scroll-enabled {
    overflow: scroll
  }
  
  @media(max-width:1024px) {
    .c-swiper {
      margin-left: -5px;
      width: calc(100% + 10px)
    }
  }
  
  .is-desktop .c-swiper {
    margin-top: -40px;
    padding-top: 40px
  }
  
  .is-mobile .c-swiper,
  .is-tablet .c-swiper {
    margin: 0;
    padding: 0 45px;
    width: 100%
  }
  
  @media(max-width:1024px) {
  
    .is-mobile .c-swiper,
    .is-tablet .c-swiper {
      padding: 0 15px
    }
  }
  
  .c-swiper-item {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    height: inherit;
    width: 100%
  }
  
  .c-swiper-item.is-placeholder {
    width: 6.65%
  }
  
  .is-mobile .c-games-swiper-section .c-swiper-item,
  .is-tablet .c-games-swiper-section .c-swiper-item {
    justify-content: flex-start;
    min-width: 134px;
    padding: 5px 10px 5px 0;
    width: 134px
  }
  
  .is-mobile .c-games-swiper-section .c-swiper-item:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item:last-child {
    min-width: 169px;
    padding: 5px 45px 5px 0;
    width: 169px
  }
  
  @media(max-width:1024px) {
  
    .is-mobile .c-games-swiper-section .c-swiper-item:last-child,
    .is-tablet .c-games-swiper-section .c-swiper-item:last-child {
      min-width: 139px;
      padding: 5px 15px 5px 0;
      width: 139px
    }
  }
  
  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins,
  .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins {
    min-width: 305px;
    width: 305px
  }
  
  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
  .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
    min-width: 340px;
    width: 340px
  }
  
  @media(max-width:1024px) {
  
    .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child,
    .is-tablet .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
      min-width: 310px;
      width: 310px
    }
  }
  
  @media(max-width:478px) {
    .is-mobile .c-games-swiper-section .c-swiper-item {
      min-width: 34%;
      width: 34%
    }
  
    .is-mobile .c-games-swiper-section .c-swiper-item:last-child {
      min-width: 31.375%;
      padding-right: 0;
      width: 31.375%
    }
  }
  
  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins {
    max-width: 305px;
    min-width: auto;
    width: 64.35%
  }
  
  .is-mobile .c-games-swiper-section .c-swiper-item.is-livespins:last-child {
    max-width: 295px;
    min-width: auto;
    padding-right: 0;
    width: 61.45%
  }
  
  .c-swiper-side-gradient {
    height: calc(100% - 20px);
    position: absolute;
    top: 10px;
    z-index: 4
  }
  
  @media(max-width:1024px) {
    .c-swiper-side-gradient {
      height: calc(100% - 10px);
      top: 5px
    }
  }
  
  .c-swiper-side-placeholder {
    background: linear-gradient(90deg, #1b1928, rgba(27, 25, 40, 0));
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity .25s ease-in;
    width: .5%
  }
  
  .c-swiper-side-placeholder.is-visible {
    opacity: 1
  }
  
  .c-swiper-side-navigation {
    background: linear-gradient(90deg, rgba(27, 25, 40, 0) 40%, #1b1928);
    pointer-events: none;
    right: 0;
    transition: all .25s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    width: 5%
  }
  
  .c-swiper-side-navigation.should-hide-gradient {
    background: none
  }
  
  .c-swiper-side-navigation .c-swiper-navigation {
    align-items: flex-end;
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    justify-content: center;
    width: 100%
  }
  
  .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
    -webkit-backdrop-filter: blur(14px);
    backdrop-filter: blur(14px);
    margin-right: 0;
    opacity: 0;
    pointer-events: all;
    transition: opacity .25s ease-in
  }
  
  .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow:first-child {
    margin-top: 10px
  }
  
  .c-swiper-container:hover .c-swiper-side-navigation .c-swiper-navigation .c-swiper-navigation__arrow {
    opacity: 1
  }
  
  .is-desktop .c-swiper-side-navigation,
  .is-desktop .c-swiper-side-placeholder {
    bottom: 10px;
    height: calc(100% - 60px);
    top: auto
  }
  
  @media(max-width:1024px) {
  
    .is-desktop .c-swiper-side-navigation,
    .is-desktop .c-swiper-side-placeholder {
      bottom: 5px;
      height: calc(100% - 50px)
    }
  }
  
  .c-game-badges {
    position: absolute;
    justify-content: space-between;
    top: 15px;
    right: 7%;
    display: flex;
    width: 85%;
  }
  
  .c-game-badges-collection {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    z-index: 5;
  }
  
  .c-game-badges span {
    flex: 0 0 auto;
    margin-right: 3px;
  }
  
  .c-game-badge.is-exclusive {
    background-color: #00bd71;
  }
  
  .c-game-badges span:last-child {
    margin-right: 0;
  }
  
  .c-game-badges .c-game-badge__text {
    margin: 0;
    /* position: relative;
      top: 15px; */
  }
  
  .c-game-badge {
    fill: #fff;
    align-items: center;
    border-radius: 4px;
    color: #fff;
    cursor: default;
    display: flex;
    font-size: 11px;
    font-weight: 700;
    height: 24px;
    line-height: 24px;
    padding: 0 4px;
    position: relative;
  }
  
  .c-swiper-container .game .c-game-badges .game-badge-provider {
    display: none;
  }
  
  .c-swiper-container .c-game-container:hover .c-game-badges .game-badge-provider {
    display: flex;
    z-index: 5;
  }
  
  @media (hover: hover) {
    .c-game-container:hover .c-game-box__image {
      transform: scale3d(1.05, 1.05, 1);
      z-index: 4;
      position: relative;
    }
  
    .c-game-container:hover {
      background: #3c3955;
    }
  }
  
  .c-swiper-container .game .c-game-box img {
    border-radius: 10px;
  }
  
  .c-swiper-item-games {
    display: flex;
  }
  
  .c-game-badge.is-bs-original {
    background-color: #e31351;
    /* margin-right: 5px; */
  }
  
  .c-game-badge__iconb {
    width: 0;
  }
  
  .sprite-icons {
    width: 0;
  }
  
  .c-game-badge.is-jackpot {
    background: #854dff;
    color: #fff;
  }
  
  .c-game-badge.is-new {
    background-color: #e31351;
  }
  
  .c-swiper-wrapper {
    margin: 0 auto 30px;
    max-width: 1420px;
    padding: 0 45px;
    width: 100%;
  }
  
  .c-swiper-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px;
    position: relative;
    z-index: 2;
  }
  
  .c-swiper-header__box {
    align-items: center;
    display: flex;
  }
  
  .games-sections__title {
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
    margin: 0 15px 0 0;
    -webkit-text-decoration: none;
    text-decoration: none;
    text-transform: uppercase;
  }
  
  .games-sections__title.is-clickable {
    cursor: pointer;
  }
  
  .homepage-variation-2 .games-sections__title {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    text-transform: capitalize;
  }
  
  .c-swiper-navigation {
    align-items: center;
    display: flex;
  }
  
  .c-swiper-header__box {
    align-items: center;
    display: flex;
  }
  
  .c-swiper-container {
    overflow: hidden;
    position: relative;
  }
  
  .is-desktop .c-swiper-container {
    margin-top: -40px;
    padding-top: 40px;
  }
  
  .c-swiper {
    -ms-overflow-style: none;
    display: flex;
    height: inherit;
    margin-left: -10px;
    overflow: hidden;
    scrollbar-width: none;
    width: calc(100% + 20px);
  }
  
  .c-swiper.scroll-enabled {
    overflow: scroll;
  }
  
  .is-desktop .c-swiper {
    margin-top: -40px;
    padding-top: 40px;
  }
  
  .c-swiper-item {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    height: inherit;
    width: 100%;
  }
  
  .c-providers-swiper__item {
    box-sizing: border-box;
    display: inline-block;
    flex: 0 1 auto;
    line-height: 0;
    padding: 10px;
    position: relative;
    width: 20%;
  }
  
  .c-provider-card {
    background: #3c3955;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 100%;
  }
  
  .is-variation-2 .c-provider-card,
  .is-variation-2 .c-provider-card:not(.is-disabled):hover {
    background: transparent;
  }
  
  .c-provider-card__body {
    align-items: center;
    aspect-ratio: 1.85;
    background-color: hsla(0, 0%, 100%, .05);
    display: flex;
    justify-content: center;
  }
  
  .is-variation-2 .c-provider-card__body {
    aspect-ratio: 2/1;
  }
  
  .c-provider-card__image {
    max-height: 80%;
    max-width: 80%;
    transition: transform .2s ease-out;
  }
  
  .c-providers-swiper__item {
    box-sizing: border-box;
    display: inline-block;
    flex: 0 1 auto;
    line-height: 0;
    padding: 10px;
    position: relative;
    width: 20%;
  }
  
  .c-provider-card {
    background: #3c3955;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    -webkit-text-decoration: none;
    text-decoration: none;
    width: 100%;
  }
  
  .is-variation-2 .c-provider-card,
  .is-variation-2 .c-provider-card:not(.is-disabled):hover {
    background: transparent;
  }
  
  .c-provider-card__image {
    max-height: 80%;
    max-width: 80%;
    transition: transform .2s ease-out;
  }
  
  .bs-c-homepage-v2-title {
    text-align: left;
  }
  
  .buy-img {
    height: 20px;
  }
  
  .games-block__button {
    margin: auto;
  }
  
  .c-site-description__section {
    text-align: left;
  }
  
  .c-game-badges .game-badge-provider {
    display: none;
  }
  
  .c-game-container:hover .c-game-badges .game-badge-provider {
    display: flex;
    z-index: 5;
  }
  
  .c-game-container .c-game-box img {
      border-radius: 10px;
  }
  </style>
  
  
  