import '/main.css'

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createI18n } from "vue-i18n"; // Correct import statement
// Importing fontawesome

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons'; // "far" icons

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
// Import translations
import translationsEn from "./locales/en.json";
import translationsFr from "./locales/fr.json";
import translationsDe from "./locales/de.json";
import translationsPt from "./locales/pt.json";
import translationsRu from "./locales/ru.json";
import translationsTr from "./locales/tr.json";
import translationsTn from "./locales/tn.json";
import './registerServiceWorker'

// Add the Font Awesome icon set to the library
library.add(fas, far);

const app = createApp(App);

// Register the FontAwesomeIcon component
app.component('font-awesome-icon', FontAwesomeIcon);

const changeLanguage = (langChange) => {
  i18n.global.locale = langChange; // Directly set the new locale
  localStorage.setItem("storeLanguage", langChange);
  toast("language change successfully");
  setTimeout(() => {
    window.location.reload(); 
  }, 1000); 
};

app.config.globalProperties.$changeLanguage = changeLanguage;

const preferredLanguage = localStorage.getItem('storeLanguage');

// Create VueI18n instance
const i18n = createI18n({
  legacy: true, // Ensure that you're using the Composition API
  locale: preferredLanguage || "en", // Default locale
  messages: {
    en: translationsEn,
    fr: translationsFr,
    de: translationsDe,
    pt: translationsPt,
    ru: translationsRu,
    tr: translationsTr,
    tn: translationsTn
  },
});

app.use(router);
app.use(i18n); // Use the created i18n instance

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

console.log(process.env.VUE_APP_API_KEY, "checking endpoints");

app.mount("#app");
